import './App.css';
import { db, auth, storage } from './firebaseData';
import _ from 'lodash';
import { strings } from './translations';
import axios from 'axios';
import {
  doc,
  onSnapshot,
  collection,
  query,
  where,
  limit,
  getDocs,
  orderBy,
} from 'firebase/firestore';
import { Link, useParams } from 'react-router-dom';
import * as React from 'react';
import 'react-phone-number-input/style.css';
import {
  onAuthStateChanged,
} from 'firebase/auth';
import MuiAlert from '@mui/material/Alert';
import {
  Link as _ScrollLink,
  animateScroll as scroll,
} from 'react-scroll';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const emails = ['username@gmail.com', 'user02@gmail.com'];

export function withRouter(Children) {
  return (props) => {
    const match = { params: useParams() };
    return <Children {...props} match={match} />;
  };
}


class Detail extends React.Component {
  constructor(props, context) {
    super(props, context);
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
        // console.log('signed', user);
        const menuDoc = doc(db, 'users', uid);
        this.setState({
          orders: [],
        });
        var orders = [];
        const { id } = this.props.match.params;
        const q = query(
          collection(db, 'orders'),
          where('restId', '==', id),
          where('userId', '==', uid),
          where('delivered', '==', false),
          orderBy('createdOn', 'desc')
        );
        await onSnapshot(q, async (data) => {
          this.setState({
            orders: [],
          });
          if (data.docs.length <= 0 && this.state.trackingId) {
            this.setState({
              showTracking: false,
              trackingDetails: null,
              trackingId: null,
            });
          }
          data.docs.forEach(async (document) => {
            orders = orders.filter((element) => element.id !== document.id);
            if (
              this.state.trackingId &&
              this.state.trackingId === document.id
            ) {
              await this.setState({ trackingDetails: document.data() });
            }
            orders.push(document);
            await this.setState({ orders: orders });
            // console.log('orders', orders);
          });
        });

        const docMenuSn = onSnapshot(menuDoc, async (docMenuSnap) => {
          if (docMenuSnap.data()) {
            if (docMenuSnap.data().phoneNumberVerified) {
              if (this.state.openLogin) {
                this.setState({ loading: false, openLogin: false });
                this.setState({
                  openAlert: true,
                  alertType: 'success',
                  alertMsg: strings['Inicio de sesión exitoso'],
                });
              }
              var balance = 0;
              if ((docMenuSnap.data())['walletBalance'] != null) {
                balance = (docMenuSnap.data())['walletBalance'] + 0.0;
              }
              this.setState({
                userId: uid,
                balance: balance,
                userDetails: docMenuSnap.data(),
                photoURL: user.photoURL,
                cards: docMenuSnap.data().cards ? docMenuSnap.data().cards : [],
                allergy: docMenuSnap.data().allergy ? docMenuSnap.data().allergy : [],
                offersUsed: docMenuSnap.data().offersUsed
                  ? docMenuSnap.data().offersUsed
                  : [],
              });
              var offers2 = this.state.offers3;
              if ((docMenuSnap.data())['offers']) {
              for (var i = 0; i < (docMenuSnap.data())['offers'].length; i++) {
                const posF = offers2.map(e => e.id).indexOf((docMenuSnap.data())['offers'][i]['id']);
                offers2.slice(posF,1);
                if ((docMenuSnap.data())['offers'][i]['enabled'])
                  offers2.push((docMenuSnap.data())['offers'][i]);
              }
            }
            if ((docMenuSnap.data())['newOffers']) {
              for (var i = 0; i < (docMenuSnap.data())['newOffers'].length; i++) {
                const posF = offers2.map(e => e.id).indexOf((docMenuSnap.data())['newOffers'][i]['id']);
                offers2.slice(posF,1);
                if ((docMenuSnap.data())['newOffers'][i]['enabled'])
                  offers2.push((docMenuSnap.data())['newOffers'][i]);
              }
            }
            console.log('offers2', offers2);
            this.setState({offers3: offers2});
              // if (docMenuSnap.data()['custId'] == null) {
              //   try {
              //     var response = await axios.post(
              //       'https://traitte-order.herokuapp.com/drivers/createcust/',null,
              //       {
              //       params: {
              //         'email': docMenuSnap.data().email,
              //         'name': docMenuSnap.data().fullName,
              //         'phone': docMenuSnap.data().phoneNumber,
              //         'uid': uid,
              //       },
              //     }
              //     );
              //     if (response.status === 200) {
              //       console.log(response.data['id']);
              //       if (response.data['id'] != null) {
              //       } else {
              //         console.log('failed');
              //       }
              //     } else {
              //       console.log('failed');
              //     }
              //   } catch (e) {
              //     console.log('exception $e',e);
              //   }
              // } else {
              //   // custId = (event.data() as Map)['custId'].toString();
              //   // print(custId);
              //   // //custId = 'cus_JwCyg2xRg0XoOT';
              //   // updateCards();
              // }
            } else {
              if(!this.state.openOTP && this.state.isMounted && this._ismounted) {
              this.setState({
                openOTP: true,
                phoneNumber: docMenuSnap.data().phoneNumber,
                phoneCountry: '+52',
                showPassword: false,
              });
              const min = 0;
              const max = 999999;
              const rand = Math.floor(min + Math.random() * (max - min));
              var dataSend = rand.toString();
              for (var i = 0; i < 6; i++) {
                if (dataSend.length < 6) {
                  dataSend = '0' + dataSend;
                }
              }
              // console.log(dataSend,this.state.isMounted, this._ismounted);
              this.setState({ otpSend: dataSend });
              await axios
                .post('https://traitte-order.herokuapp.com/drivers/create-new-otp/', {
                  code: dataSend,
                  number: docMenuSnap.data().phoneNumber,
                  uuid: uid
                })
                .then(async (response) => {
                  console.log(response);
                });
              }
              var balance = 0;
              if ((docMenuSnap.data())['walletBalance'] != null) {
                balance = (docMenuSnap.data())['walletBalance'] + 0.0;
              }
              this.setState({
                userId: uid,
                balance: balance,
                userDetails: docMenuSnap.data(),
                photoURL: user.photoURL,
                cards: docMenuSnap.data().cards ? docMenuSnap.data().cards : [],
                allergy: docMenuSnap.data().allergy ? docMenuSnap.data().allergy : [],
                offersUsed: docMenuSnap.data().offersUsed
                  ? docMenuSnap.data().offersUsed
                  : [],
              });
              var offers2 = this.state.offers3;
              if ((docMenuSnap.data())['offers']) {
              for (var i = 0; i < (docMenuSnap.data())['offers'].length; i++) {
                const posF = offers2.map(e => e.id).indexOf((docMenuSnap.data())['offers'][i]['id']);
                offers2.slice(posF,1);
                if ((docMenuSnap.data())['offers'][i]['enabled'])
                  offers2.push((docMenuSnap.data())['offers'][i]);
              }
            }
            if ((docMenuSnap.data())['newOffers']) {
              for (var i = 0; i < (docMenuSnap.data())['newOffers'].length; i++) {
                const posF = offers2.map(e => e.id).indexOf((docMenuSnap.data())['newOffers'][i]['id']);
                offers2.slice(posF,1);
                if ((docMenuSnap.data())['newOffers'][i]['enabled'])
                  offers2.push((docMenuSnap.data())['newOffers'][i]);
              }
            }
            console.log('offers2', offers2);
            this.setState({offers3: offers2});
            }
          } else {
            // console.log('openlogin',this.setState.openLogin);
            if (this.state.openLogin) {
              this.setState({
                openAlert: true,
                alertType: 'error',
                alertMsg: 'Error de inicio de sesión',
              });
            }
            if (!this.state.openRegister) {
              this.setState({ loading: false });
              this.setState({ userId: null, userDetails: null, cards: [], allergy: [] });
              auth.signOut();
            }
          }
        });
        // ...
      } else {
        this.setState({ userId: null, userDetails: null, cards: [], allergy: [] });

        //       signInWithEmailAndPassword(auth, 'demo@demo.com', 'demo1234')
        // .then((userCredential) => {
        //   // Signed in
        //   console.log('signing',userCredential);
        //   const user = userCredential.user;
        //   const uid = user.uid;
        //   this.setState({ userId: uid });
        //   // ...
        // })
        // .catch((error) => {
        //   const errorCode = error.code;
        //   const errorMessage = error.message;
        //   console.log(errorMessage,errorCode);
        // });
        // User is signed out
        // ...
      }
    });
    var days = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    this._ismounted = true;
    this.state = {
      orders: [],
      addedNotes: false,
      orderLoading: false,
      codeData: '',
      codeError: false,
      canPickup: false,
      toggledOff:false,
      cashOnly:false,
      newAllergy: '',
      busId: '',
      alertMsg: '',
      openAddCard: false,
      allergyActivate: true,
      newCard: {
        cardNo: '',
        cvv:'',
        month: '',
        year: '',
        expiry: '',
        name: '',
        'save': true,
      },
      selectLocationName: null,
      showAllergyDisclaimer: true,
      canDeliverHere: false,
      openAlert: false,
      showCart: false,
      showAllergy: false,
      showTracking: false,
      trackingDetails: null,
      toggleDetails: false,
      trackingId: null,
      showDiscount: false,
      showPayment: false,
      alertType: 'error',
      isMounted: true,
      anchorEl: null,
      open: false,
      openLocation: false,
      openCards: false,
      enteredAmount: 0,
      openWallet: false,
      showOtherWallet: false,
      openPreOrder: false,
      showTomorrow: false,
      show3Day: false,
      show4Day: false,
      show5Day: false,
      show6Day: false,
      show7Day: false,
      selectedPreOrder: null,
      currenciesList2Selected: null,
      currenciesListSelected: null,
      currenciesList3Selected: null,
      currenciesList4Selected: null,
      currenciesList5Selected: null,
      currenciesList6Selected: null,
      currenciesList7Selected: null,
      currenciesList8Selected: null,
      openLogin: false,
      showGuest: false,
      isGuest: false,
      guestOrder: null,
      showAdditionalDetails: false,
      guestDetails: {
        'name': '',
        'email': '',
        'phone': ''
      },
      openBusinessInfo: false,
      password: '',
      email: '',
      fullName: '',
      offersDetails: {},
      offersDetails2: {},
      tempOrder: {},
      hasAllData: true,
      productIds: [],
      otp: '',
      otpSend: '',
      phoneNumber: '',
      phoneCountry: '+52',
      showPassword: false,
      openRegister: false,
      openOTP: false,
      showMinimum: false,
      openDialogAlert: false,
      openDialogCancel: false,
      openVariable: false,
      selectedProduct: null,
      userId: null,
      showAllergyData: true,
      allergy: [],
      selectedValue: emails[1],
      selectedLocationName: '',
      selectedLocationNotes: '',
      currentLocation: {
        latitude: 0,
        longitude: 0,
      },
      currentAddress: '',
      selectedLocationData: '',
      selectedLocationId: '',
      selectedLocation: {
        latitude: 0,
        longitude: 0,
      },
      businessData: null,
      locOptions: [],
      offers: [],
      offers2: [],
      offers3: [],
      addresses: [],
      defaultCountry: 'MX',
      selectedLocationDetails: {},
      cards: [],
      loading: false,
      offersUsed: [],
      cart: {
        restId: '',
        restName: '',
        restLogo: '',
        maxTime: 0,
        totalCost: 0,
        totalItems: 0,
        item: [],
        totalOxxoCost: 0,
        totalOxxoItems: 0,
        oxxoNameData: '',
        oxxoLogoImage: '',
        oxxoItem: [],
      },
      delivery: 0,
      selectedMenuIndex: 0,
      menuProductItems: [],
      selectedType: 0,
      hasClickedLoc: false,
      autoLoc: '',
      offerDetails: {},
      onlyPickup: false,
      storeIsOpen: false,
      deliveryFee: 0,
      dynamicFeeType: 0,
      selectLocationData: {
        id: '',
        name: '',
        address: '',
        geoAddress: '',
        addressNotes: '',
        latitude: 10.99835602,
        longitude: 77.01502627,
      },
      cookingInstructions: '',
      spoon: false,
      selectedAddress: {
        latitude: 0,
        longitude: 0,
      },
      defaultProps: {
        center: {
          lat: 10.99835602,
          lng: 77.01502627,
        },
        zoom: 16,
      },
      circleData: null,
      defaultPropsCircle: {
        center: {
          lat: 10.99835602,
          lng: 77.01502627,
        },
        zoom: 16,
      },
      mobileMoreAnchorEl: null,
      menuData: [],
      menu: [],
      upsellingProd: [],
      upsellingProd2: [],
      userDetails: null,
      width: 0,
      height: 0,
      showAddressModal: false,
      selectedDate: new Date(),
      selectedWeekDay: new Date().getDay(),
      isTomorrow: false,
      isToday: false,
      isPreOrder: false,
      isNewUser: true,
      isStore: false,
      order: {
        offerCode: '',
        selectedOffer: {},
        selectedOffer2: {},
        offerId: '',
        offerValue: 0,
        cashbackValue: 0,
        cashbackValue2: 0,
        offerCode2: '',
        offerId2: '',
        offerValue2: 0,
        restId: '',
        restName: '',
        restLogo: '',
        maxTime: 0,
        itemCost: 0,
        deliveryCharge: 0,
        usageFee: 0,
        taxes: 0,
        serviceFee: 0,
        grandTotal: 0,
        items: [],
      },
      offerCode: '',
      usingWallet: false,
      selectedOffer: null,
      offerId: '',
      offerSuccess: '',
      offerErrorText: '',
      offerValue: 0,
      cashbackValue: 0,
      offerInd: null,
      isOfferApplied: false,
      isOfferError: false,
      isFreeDelivery: false,
      offerCode2: '',
      selectedOffer2: null,
      offerId2: '',
      offerSuccess2: '',
      offerErrorText2: '',
      offerValue2: 0,
      balance: 0,
      cashbackValue2: 0,
      offerInd2: null,
      isOfferApplied2: false,
      isOfferError2: false,
      isFreeDelivery2: false,
      deliveryInstructions: '',
      defaultCard: null,
      toggleDelivery: true,
      tipPercent: 0,
      dynamicDelivery: false,
      additionalDelivery: 0,
      normalDelivery: 0,
      additionalDeliveryPercentage2: 0,
      reasonTitle: '',
      reasonDescription: '',
      isStore2: false,
      showMap: false,
      currenciesList: [],
      currenciesList3: [],
      currenciesList4: [],
      currenciesList5: [],
      currenciesList6: [],
      currenciesList7: [],
      currenciesList8: [],
      currenciesList2: [],
      vh: window.innerHeight * 0.01,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    this.setState({ vh: vh });
    // We listen to the resize event
    window.addEventListener('resize', () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      this.setState({ vh: vh });
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  render() {    
    return (
      <>
	  <div style={{margin:'20px'}}>
        <p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'center', lineHeight:'36px', backgroundColor:'#ffffff'}}>
		<span style={{fontFamily:'Arial', fontSize:'24px', color:'#212529', fontWeight: '700'}}>Aviso de Privacidad</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>El presente aviso de privacidad regula la forma en que trataremos los datos que nos proporciones y aquellos que, con fines estadísticos, recabemos cuando ingreses y utilices nuestro sitio de internet: </span>
		<a href="https://titodelivery.online" style={{textDecoration:'none'}}>
		<u><span style={{fontFamily:'Arial', color:'#0563c1'}}>titodelivery.online</span></u>
		</a>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<u><span style={{fontFamily:'Arial'}}>1. De tus datos</span></u>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Appeptito se compromete a no vender, intercambiar, transferir, compartir, publicar o difundir a terceros ajenos a Appeptito, sin tu autorización los datos que nos proporciones mediante los formularios de registro o de contacto de nuestro Sitio. Tus datos son tuyos. La única forma en que nos veríamos obligados a revelar tus datos es mediante la orden de una autoridad competente.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<u><span style={{fontFamily:'Arial'}}>2. De tu autorización</span></u>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Al registrarte para utilizar nuestro Sitio, autorizas a Appeptito a recabar y tratar los datos personales especificados en el punto 5 del presente aviso de privacidad para los fines mencionados en el punto 4 de éste mismo aviso. Así mismo, tu registro constituye tu conformidad y aceptación de nuestro aviso de privacidad y las modificaciones que eventualmente pueda llegar a tener.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<u><span style={{fontFamily:'Arial'}}>3. De tus derechos ARCO </span></u>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>En todo momento, mediante el ingreso a tu cuenta, podrás acceder, rectificar o cancelar el registro de los datos que nos has proporcionado. De la misma forma, podrás oponerte a que tus datos continúen en nuestra base y darte de baja de la misma. Si tienes alguna duda o no puedes acceder a tu cuenta o tus datos, por favor envía un correo electrónico a </span>
		<a href="mailto:contacto@titodelivery.online" style={{textDecoration:'none'}}>
		<u><span style={{fontFamily:'Arial', color:'#0563c1'}}>contacto@titodelivery.online</span></u>
		</a>
		<span style={{fontFamily:'Arial'}}></span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>&#xa0;</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<u><span style={{fontFamily:'Arial'}}>4. Del uso de tu información por parte de Appeptito</span></u>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>La información personal que nos proporciones será utilizada para:</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>a- Contactarte sobre cuestiones relacionadas con tu cuenta.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>b- Hacerte llegar comunicaciones sobre eventos, promociones o noticias de nuestros aliados comerciales con fines de mercadotecnia, publicidad o prospección comercial.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>c- Personalizar tu experiencia en la navegación de nuestros sitios.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>d- Facilitar el proceso de compras a través de nuestro sitio.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>e- Notificarte los cambios que puedan darse en nuestro aviso de privacidad. Las finalidades señaladas en los incisos a y e son necesarias para la existencia, mantenimiento y cumplimiento de la relación entre tú, como usuario de nuestro sitio, y Appeptito. Las finalidades señaladas en los incisos b, c y d no son necesarias para la existencia, mantenimiento y cumplimiento de la relación entre tú, como usuario de nuestros sitios y Appeptito, pero sí son necesarios para mejorar tu experiencia con relación a nuestros productos y servicios.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<u><span style={{fontFamily:'Arial'}}>5. De la información que recolectamos</span></u>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>La información que recolectamos y cuyo tratamiento regula el presente aviso de privacidad es la siguiente:</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>a- Para tu suscripción al boletín de nuestro sitio recabamos tu correo electrónico. Si deseas suscribirte a nuestro boletín utilizando tu usuario y contraseña de Facebook o Gmail, además de tu correo electrónico recabaremos tu nombre de usuario, nombre y apellidos.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>b- Para ponernos en contacto contigo con relación a algún comentario o sugerencia que nos hagas mediante nuestro formato de contacto, recabamos tu nombre, apellidos, teléfono y correo electrónico.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>c- Para personalizar tu experiencia en la navegación de nuestro sitio recabamos el sistema operativo que utilizas, el navegador con que nos visitas, las páginas y artículos que visitas dentro de nuestro sitio, el tiempo que nos haces el favor de permanecer en nuestro sitio, los artículos que marcas como favoritos, los artículos que compartes en alguna red social mediante la herramienta que proporcionamos en cada artículo, dirección IP, nombre de usuario y contraseña.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>d- Para facilitar el proceso de compra a través de nuestro Sitio recabamos tu nombre, apellidos, domicilio, número de tarjeta de crédito o débito, fecha de expiración de tu tarjeta de crédito o débito, el código de verificación de tu tarjeta de crédito o débito, tipo de tarjeta de crédito o débito e institución emisora de tu tarjeta de crédito o débito.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>e- Para enviarte información sobre eventos, promociones o noticias sobre Appeptito o nuestros aliados comerciales, recabamos tu correo electrónico.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>f- Para notificarte los cambios que puedan darse en nuestro aviso de privacidad recabamos tu correo electrónico.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>G- Ordenar, clasificar, dividir o separar y almacenar los datos personales dentro del Sitio, los sistemas y archivos de Appeptito.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>h- Creación, modificación y administración de la cuenta del Usuario.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>i- Realizar procesos al interior de Appeptito, con fines de desarrollo operativo y/o de tecnologías.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>j- Realizar estrategias de mercado mediante el estudio del comportamiento del Usuario frente a las ofertas y con ello mejorar en su contenido, personalizando presentación y servicio.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>k- Creación de prospecciones comerciales y segmentación de mercados.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>l- Realizar encuestas de satisfacción y ofrecimiento o reconocimiento de beneficios propios de nuestro programa de lealtad y servicio post venta, para calificar el servicio y la atención por medio de los canales dispuestos para ello.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>m- Presentar reportes ante las autoridades de inspección, vigilancia y control, y tramitar los requerimientos realizados por entidades administrativas o judiciales.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>n- Transferencia o transmisión de datos nacional o internacionalmente a proveedores con los que Appeptito desarrolle actividades en cumplimiento de su objeto social. Así mismo, se podrá hacer transferencia a los aliados estratégicos de la empresa para que ejecuten actividades de marketing, publicidad y promociones asociadas con el objeto social' todo de acuerdo con las disposiciones aplicables.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>o- Reportes a centrales de riesgo por incumplimiento de las obligaciones financieras derivadas de la relación comercial.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>p- Solicitar la autorización de cobro ante las entidades definidas y autorizadas para ello.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>q- Recolectar tu ubicación para mantener el control de la entrega de pedidos.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<u><span style={{fontFamily:'Arial'}}>6. Términos de uso y privacidad de tu ubicación</span></u>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Appeptito te pedirá permiso para tener acceso a tu ubicación, tú podrás elegir si le permites acceder a esta, pero debes tener en cuenta que, de negarte, nuestro Sitio no podrá realizar sus funciones de una manera plena.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Appeptito tendrá acceso a tu ubicación para los siguientes fines:</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>a- Como usuario:</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>i. Appeptito accederá a tu ubicación con el fin de que tengamos en cuenta el destino final en el que el pedido deberá ser entregado. Podrás hacer la selección manual de la ubicación en caso de que tu pedido sea destinado a un lugar distinto.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>i. Appeptito REPARTIDOR accederá en segundo plano a tu ubicación para monitorear el traslado del producto hasta la entrega final.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>ii. Appeptito REPARTIDOR accederá en segundo plano a tu ubicación como medio de protección al repartidor en caso de que sufra percance alguno durante el traslado del producto.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>iii. Appeptito REPARTIDOR accederá en segundo plano a tu ubicación para notificarte cuando exista un pedido de Appeptito cerca de ti.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>De ninguna manera y por ningún motivo Appeptito y/o Appeptito REPARTIDOR compartirá tu ubicación con persona alguna, salvo en los casos previstos en el numeral 15.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<u><span style={{fontFamily:'Arial'}}>7. Cookies</span></u>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Si lo autorizas y la configuración de tu máquina nos lo permite, enviaremos un archivo llamado “cookie” que se instalará en tu disco duro para permitirnos identificar las preferencias que hayas seleccionado, en su caso, para mejorar tu experiencia en nuestro sitio conforme a la información mencionada en el inciso c) del punto 5 de este aviso de privacidad. Ninguna de la información que se almacene en el “cookie” será divulgada por Appeptito</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Para no dejar que se instale nuestro “cookie” deberás configurar tu navegador para no aceptar cookies del sitio Appeptito.store</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Para borrar el “cookie” que se haya instalado con tu autorización deberás de ingresar a las preferencias de tu navegador, ubicar la pestaña o sección de administración de “cookies” y borrar el “cookie” correspondiente al sitio Appeptito.store</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Al borrar o impedir que se instale el “cookie” de nuestro sitio, puedes perder la configuración que hayas realizado para personalizar tu experiencia en nuestro sitio o impedir dicha personalización.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<u><span style={{fontFamily:'Arial'}}>8. Activación de cuenta</span></u>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Por cuestiones de seguridad, al darte de alta en nuestro sitio y consentir el tratamiento de tus datos conforme al presente aviso de privacidad, te será enviado un correo de verificación y validación que deberás de confirmar para concluir tu aceptación y registro.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<u><span style={{fontFamily:'Arial'}}>9. Garantía de seguridad</span></u>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>No obstante que Appeptito utiliza la tecnología más avanzada para salvaguardar los datos que nos proporcionas, existe la probabilidad de que pueda haber un defecto en la programación de las aplicaciones que utilizamos, que permita el acceso forzado a la información. Aún y cuando esta situación se actualizará, tus datos están protegidos mediante procesos de cifrado avanzado que hacen imposible la recuperación útil de la información por un tercero ajeno a Appeptito Nuestra garantía es hacer todo lo que razonablemente esté a nuestro alcance para resguardar tus datos.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<u><span style={{fontFamily:'Arial'}}>10. Información de otros sitios</span></u>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Debes tomar en cuenta que la información que alimentas en sitios ajenos a Appeptito puede coincidir con aquella que has alimentado o alimentarás en los formularios de Appeptito En consecuencia, debes estar consciente y ser responsable de tener el mismo cuidado que tienes con tu información en los sitios de Appeptito, en los demás sitios en los que navegues o alimentes información de cualquier tipo.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<u><span style={{fontFamily:'Arial'}}>11. Cambios en la política</span></u>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Es posible que, eventualmente, se realicen cambios a la presente política de privacidad. Te sugerimos visitar frecuentemente el Sitio para estar al tanto de los posibles cambios o modificaciones que pudiera haber. No te preocupes, con todo y cambios, tu información estará segura con Appeptito</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Además, cuando se den cambios en nuestro aviso de privacidad, te lo notificaremos al correo electrónico que tengas registrado con nosotros. De esa forma, siempre estarás al tanto de la última versión de nuestro aviso de privacidad.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<u><span style={{fontFamily:'Arial'}}>12. Contacto y Encargado</span></u>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Si tienes alguna duda o preocupación con relación a la presente política de privacidad o la forma en que manejamos los datos que nos proporcionas o recolectamos, por favor envíanos un correo electrónico a contacto@titodelivery.online Nuestro compromiso es contestarte en un plazo no mayor a 72 horas, salvo en caso de una excesiva carga de trabajo, caso fortuito o de fuerza mayor.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<u><span style={{fontFamily:'Arial'}}>13. Identidad y Domicilio convencional</span></u>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Tus datos son tratados y resguardados por “Appeptito” (Yesica Flores Santamaria) es el nombre comercial debidamente registrada y constituida conforme a las leyes de los Estados Unidos Mexicanos, con domicilio para oír y recibir notificaciones en Encinos 27, Casa Blanca, Metepec, C.P. 52175, Edo de Méx' en su carácter de responsable tratará los datos personales del Titular. Appeptito es una plataforma virtual que presta servicios de tecnología. En dicha plataforma se exhiben productos y servicios de diversos establecimientos de comercio ajenos a Appeptito para que sean adquiridos por los usuarios.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<u><span style={{fontFamily:'Arial'}}>14. Límites en el uso o divulgación de tus datos</span></u>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Si en un momento dado deseas limitar el uso de tus datos por parte de Appeptito, por ejemplo, cancelar tu suscripción a una lista de noticias, pero deseas seguir utilizando los servicios del sitio o recibir otro tipo de comunicados, en el panel de control de tu cuenta puedes seleccionar, habilitar y deshabilitar los usos que, conforme a este aviso de privacidad, nos has autorizado. Te pedimos esperar cinco días hábiles posteriores al cambio para que el mismo se vea reflejado. Esto quiere decir que en ese tiempo es probable que sigas recibiendo, de manera provisional, los comunicados deshabilitados.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>De igual forma, puedes solicitar la limitación en el uso de tus datos personales enviando un correo electrónico a contacto@titodelivery.online con atención al Departamento de Atención a Usuarios.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Ten en cuenta que al limitar el uso de tus datos es posible que no podamos continuar prestándote uno o más de los servicios que hayas solicitado y para los que se necesitan los datos cuyo uso estás limitando.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<u><span style={{fontFamily:'Arial'}}>15. Transferencia de tus datos</span></u>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Al aceptar el presente aviso de privacidad, autorizas a Appeptito a transferir los datos que nos proporciones o aquellos que recolectemos, a sus socios comerciales. En todo momento, Appeptito se asegurará de que sus socios comerciales conozcan el presente aviso de privacidad y se obliguen a acatarlo y respetarlo.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Sus datos personales podrán ser transferidos dentro del territorio nacional o hacia el extranjero, conforme a lo siguiente:</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>1. A autoridades, organismos o entidades gubernamentales, en cumplimiento a o en relación con las obligaciones contempladas en la legislación aplicable al Responsable, sus subsidiarias y/o afiliadas, así como en cumplimiento de requerimientos efectuados a las mismas.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>2. A autoridades, organismos o entidades gubernamentales, cuando la transferencia sea precisa para el reconocimiento, ejercicio o defensa de un derecho del responsable, sus subsidiarias y/o afiliadas en un proceso judicial.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>3. A compañías y agentes de seguro, cuando la transferencia sea necesaria por virtud de un contrato celebrado o por celebrar en interés del titular, mismo que tendrá como objeto estar en posibilidad de ofrecerle seguros personalizados y descuentos en la adquisición seguros, en caso que así lo solicite.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>4. A compañías afiliadas o subsidiarias del Responsable, con finalidades de valoración y análisis de perfiles de los usuarios, resguardo centralizado de la información y para fines estadísticos y de registro histórico de usuarios del Responsable, sus subsidiarias y/o afiliadas.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>5. A terceros, derivado de una reestructura corporativa del Representante, incluyendo, la fusión, consolidación, venta, liquidación o transferencia de activos.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>6. A terceros, cuando la transferencia sea necesaria para el mantenimiento o cumplimiento de una relación jurídica entre el responsable y el titular.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>7. En los demás supuestos establecidos en la Legislación de Datos Personales que no requieren de su consentimiento.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Las transferencias mencionadas no requieren de su consentimiento conforme a la Legislación de Datos Personales.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Le informamos que las remisiones nacionales e internacionales de Datos Personales entre el Responsable y sus encargados no requerirán ser informadas.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Si deseas revocar esta autorización, te pedimos sigas el procedimiento establecido en el punto 16 de este aviso de privacidad.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<u><span style={{fontFamily:'Arial'}}>16. Revocación de tu consentimiento</span></u>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>En cualquier momento, puedes revocar la autorización que nos hayas dado para el tratamiento de tus datos personales. La manera en que puedes llevar a cabo dicha revocación de autorización es solicitándolo mediante correo electrónico a contacto@titodelivery.online con atención al Departamento de Atención a Usuarios.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14pt', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Ten en cuenta que al revocar tu autorización es posible que no nos sea posible seguir prestándote el o los servicios para los que utilizamos los datos que recabamos.</span>
		</p>
		<div style={{height: '30px'}}></div>
		</div>
      </>
    ) ;
  }
}

export default withRouter(Detail);
