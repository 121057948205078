import './App.css';
import { db, auth, storage } from './firebaseData';
import _ from 'lodash';
import { strings } from './translations';
import axios from 'axios';
import {
  doc,
  onSnapshot,
  collection,
  query,
  where,
  limit,
  getDocs,
  orderBy,
} from 'firebase/firestore';
import { Link, useParams } from 'react-router-dom';
import * as React from 'react';
import 'react-phone-number-input/style.css';
import {
  onAuthStateChanged,
} from 'firebase/auth';
import MuiAlert from '@mui/material/Alert';
import {
  Link as _ScrollLink,
  animateScroll as scroll,
} from 'react-scroll';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const emails = ['username@gmail.com', 'user02@gmail.com'];

export function withRouter(Children) {
  return (props) => {
    const match = { params: useParams() };
    return <Children {...props} match={match} />;
  };
}


class Detail extends React.Component {
  constructor(props, context) {
    super(props, context);
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
        // console.log('signed', user);
        const menuDoc = doc(db, 'users', uid);
        this.setState({
          orders: [],
        });
        var orders = [];
        const { id } = this.props.match.params;
        const q = query(
          collection(db, 'orders'),
          where('restId', '==', id),
          where('userId', '==', uid),
          where('delivered', '==', false),
          orderBy('createdOn', 'desc')
        );
        await onSnapshot(q, async (data) => {
          this.setState({
            orders: [],
          });
          if (data.docs.length <= 0 && this.state.trackingId) {
            this.setState({
              showTracking: false,
              trackingDetails: null,
              trackingId: null,
            });
          }
          data.docs.forEach(async (document) => {
            orders = orders.filter((element) => element.id !== document.id);
            if (
              this.state.trackingId &&
              this.state.trackingId === document.id
            ) {
              await this.setState({ trackingDetails: document.data() });
            }
            orders.push(document);
            await this.setState({ orders: orders });
            // console.log('orders', orders);
          });
        });

        const docMenuSn = onSnapshot(menuDoc, async (docMenuSnap) => {
          if (docMenuSnap.data()) {
            if (docMenuSnap.data().phoneNumberVerified) {
              if (this.state.openLogin) {
                this.setState({ loading: false, openLogin: false });
                this.setState({
                  openAlert: true,
                  alertType: 'success',
                  alertMsg: strings['Inicio de sesión exitoso'],
                });
              }
              var balance = 0;
              if ((docMenuSnap.data())['walletBalance'] != null) {
                balance = (docMenuSnap.data())['walletBalance'] + 0.0;
              }
              this.setState({
                userId: uid,
                balance: balance,
                userDetails: docMenuSnap.data(),
                photoURL: user.photoURL,
                cards: docMenuSnap.data().cards ? docMenuSnap.data().cards : [],
                allergy: docMenuSnap.data().allergy ? docMenuSnap.data().allergy : [],
                offersUsed: docMenuSnap.data().offersUsed
                  ? docMenuSnap.data().offersUsed
                  : [],
              });
              var offers2 = this.state.offers3;
              if ((docMenuSnap.data())['offers']) {
              for (var i = 0; i < (docMenuSnap.data())['offers'].length; i++) {
                const posF = offers2.map(e => e.id).indexOf((docMenuSnap.data())['offers'][i]['id']);
                offers2.slice(posF,1);
                if ((docMenuSnap.data())['offers'][i]['enabled'])
                  offers2.push((docMenuSnap.data())['offers'][i]);
              }
            }
            if ((docMenuSnap.data())['newOffers']) {
              for (var i = 0; i < (docMenuSnap.data())['newOffers'].length; i++) {
                const posF = offers2.map(e => e.id).indexOf((docMenuSnap.data())['newOffers'][i]['id']);
                offers2.slice(posF,1);
                if ((docMenuSnap.data())['newOffers'][i]['enabled'])
                  offers2.push((docMenuSnap.data())['newOffers'][i]);
              }
            }
            console.log('offers2', offers2);
            this.setState({offers3: offers2});
              // if (docMenuSnap.data()['custId'] == null) {
              //   try {
              //     var response = await axios.post(
              //       'https://traitte-order.herokuapp.com/drivers/createcust/',null,
              //       {
              //       params: {
              //         'email': docMenuSnap.data().email,
              //         'name': docMenuSnap.data().fullName,
              //         'phone': docMenuSnap.data().phoneNumber,
              //         'uid': uid,
              //       },
              //     }
              //     );
              //     if (response.status === 200) {
              //       console.log(response.data['id']);
              //       if (response.data['id'] != null) {
              //       } else {
              //         console.log('failed');
              //       }
              //     } else {
              //       console.log('failed');
              //     }
              //   } catch (e) {
              //     console.log('exception $e',e);
              //   }
              // } else {
              //   // custId = (event.data() as Map)['custId'].toString();
              //   // print(custId);
              //   // //custId = 'cus_JwCyg2xRg0XoOT';
              //   // updateCards();
              // }
            } else {
              if(!this.state.openOTP && this.state.isMounted && this._ismounted) {
              this.setState({
                openOTP: true,
                phoneNumber: docMenuSnap.data().phoneNumber,
                phoneCountry: '+52',
                showPassword: false,
              });
              const min = 0;
              const max = 999999;
              const rand = Math.floor(min + Math.random() * (max - min));
              var dataSend = rand.toString();
              for (var i = 0; i < 6; i++) {
                if (dataSend.length < 6) {
                  dataSend = '0' + dataSend;
                }
              }
              // console.log(dataSend,this.state.isMounted, this._ismounted);
              this.setState({ otpSend: dataSend });
              await axios
                .post('https://traitte-order.herokuapp.com/drivers/create-new-otp/', {
                  code: dataSend,
                  number: docMenuSnap.data().phoneNumber,
                  uuid: uid
                })
                .then(async (response) => {
                  console.log(response);
                });
              }
              var balance = 0;
              if ((docMenuSnap.data())['walletBalance'] != null) {
                balance = (docMenuSnap.data())['walletBalance'] + 0.0;
              }
              this.setState({
                userId: uid,
                balance: balance,
                userDetails: docMenuSnap.data(),
                photoURL: user.photoURL,
                cards: docMenuSnap.data().cards ? docMenuSnap.data().cards : [],
                allergy: docMenuSnap.data().allergy ? docMenuSnap.data().allergy : [],
                offersUsed: docMenuSnap.data().offersUsed
                  ? docMenuSnap.data().offersUsed
                  : [],
              });
              var offers2 = this.state.offers3;
              if ((docMenuSnap.data())['offers']) {
              for (var i = 0; i < (docMenuSnap.data())['offers'].length; i++) {
                const posF = offers2.map(e => e.id).indexOf((docMenuSnap.data())['offers'][i]['id']);
                offers2.slice(posF,1);
                if ((docMenuSnap.data())['offers'][i]['enabled'])
                  offers2.push((docMenuSnap.data())['offers'][i]);
              }
            }
            if ((docMenuSnap.data())['newOffers']) {
              for (var i = 0; i < (docMenuSnap.data())['newOffers'].length; i++) {
                const posF = offers2.map(e => e.id).indexOf((docMenuSnap.data())['newOffers'][i]['id']);
                offers2.slice(posF,1);
                if ((docMenuSnap.data())['newOffers'][i]['enabled'])
                  offers2.push((docMenuSnap.data())['newOffers'][i]);
              }
            }
            console.log('offers2', offers2);
            this.setState({offers3: offers2});
            }
          } else {
            // console.log('openlogin',this.setState.openLogin);
            if (this.state.openLogin) {
              this.setState({
                openAlert: true,
                alertType: 'error',
                alertMsg: 'Error de inicio de sesión',
              });
            }
            if (!this.state.openRegister) {
              this.setState({ loading: false });
              this.setState({ userId: null, userDetails: null, cards: [], allergy: [] });
              auth.signOut();
            }
          }
        });
        // ...
      } else {
        this.setState({ userId: null, userDetails: null, cards: [], allergy: [] });

        //       signInWithEmailAndPassword(auth, 'demo@demo.com', 'demo1234')
        // .then((userCredential) => {
        //   // Signed in
        //   console.log('signing',userCredential);
        //   const user = userCredential.user;
        //   const uid = user.uid;
        //   this.setState({ userId: uid });
        //   // ...
        // })
        // .catch((error) => {
        //   const errorCode = error.code;
        //   const errorMessage = error.message;
        //   console.log(errorMessage,errorCode);
        // });
        // User is signed out
        // ...
      }
    });
    var days = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    this._ismounted = true;
    this.state = {
      orders: [],
      addedNotes: false,
      orderLoading: false,
      codeData: '',
      codeError: false,
      canPickup: false,
      toggledOff:false,
      cashOnly:false,
      newAllergy: '',
      busId: '',
      alertMsg: '',
      openAddCard: false,
      allergyActivate: true,
      newCard: {
        cardNo: '',
        cvv:'',
        month: '',
        year: '',
        expiry: '',
        name: '',
        'save': true,
      },
      selectLocationName: null,
      showAllergyDisclaimer: true,
      canDeliverHere: false,
      openAlert: false,
      showCart: false,
      showAllergy: false,
      showTracking: false,
      trackingDetails: null,
      toggleDetails: false,
      trackingId: null,
      showDiscount: false,
      showPayment: false,
      alertType: 'error',
      isMounted: true,
      anchorEl: null,
      open: false,
      openLocation: false,
      openCards: false,
      enteredAmount: 0,
      openWallet: false,
      showOtherWallet: false,
      openPreOrder: false,
      showTomorrow: false,
      show3Day: false,
      show4Day: false,
      show5Day: false,
      show6Day: false,
      show7Day: false,
      selectedPreOrder: null,
      currenciesList2Selected: null,
      currenciesListSelected: null,
      currenciesList3Selected: null,
      currenciesList4Selected: null,
      currenciesList5Selected: null,
      currenciesList6Selected: null,
      currenciesList7Selected: null,
      currenciesList8Selected: null,
      openLogin: false,
      showGuest: false,
      isGuest: false,
      guestOrder: null,
      showAdditionalDetails: false,
      guestDetails: {
        'name': '',
        'email': '',
        'phone': ''
      },
      openBusinessInfo: false,
      password: '',
      email: '',
      fullName: '',
      offersDetails: {},
      offersDetails2: {},
      tempOrder: {},
      hasAllData: true,
      productIds: [],
      otp: '',
      otpSend: '',
      phoneNumber: '',
      phoneCountry: '+52',
      showPassword: false,
      openRegister: false,
      openOTP: false,
      showMinimum: false,
      openDialogAlert: false,
      openDialogCancel: false,
      openVariable: false,
      selectedProduct: null,
      userId: null,
      showAllergyData: true,
      allergy: [],
      selectedValue: emails[1],
      selectedLocationName: '',
      selectedLocationNotes: '',
      currentLocation: {
        latitude: 0,
        longitude: 0,
      },
      currentAddress: '',
      selectedLocationData: '',
      selectedLocationId: '',
      selectedLocation: {
        latitude: 0,
        longitude: 0,
      },
      businessData: null,
      locOptions: [],
      offers: [],
      offers2: [],
      offers3: [],
      addresses: [],
      defaultCountry: 'MX',
      selectedLocationDetails: {},
      cards: [],
      loading: false,
      offersUsed: [],
      cart: {
        restId: '',
        restName: '',
        restLogo: '',
        maxTime: 0,
        totalCost: 0,
        totalItems: 0,
        item: [],
        totalOxxoCost: 0,
        totalOxxoItems: 0,
        oxxoNameData: '',
        oxxoLogoImage: '',
        oxxoItem: [],
      },
      delivery: 0,
      selectedMenuIndex: 0,
      menuProductItems: [],
      selectedType: 0,
      hasClickedLoc: false,
      autoLoc: '',
      offerDetails: {},
      onlyPickup: false,
      storeIsOpen: false,
      deliveryFee: 0,
      dynamicFeeType: 0,
      selectLocationData: {
        id: '',
        name: '',
        address: '',
        geoAddress: '',
        addressNotes: '',
        latitude: 10.99835602,
        longitude: 77.01502627,
      },
      cookingInstructions: '',
      spoon: false,
      selectedAddress: {
        latitude: 0,
        longitude: 0,
      },
      defaultProps: {
        center: {
          lat: 10.99835602,
          lng: 77.01502627,
        },
        zoom: 16,
      },
      circleData: null,
      defaultPropsCircle: {
        center: {
          lat: 10.99835602,
          lng: 77.01502627,
        },
        zoom: 16,
      },
      mobileMoreAnchorEl: null,
      menuData: [],
      menu: [],
      upsellingProd: [],
      upsellingProd2: [],
      userDetails: null,
      width: 0,
      height: 0,
      showAddressModal: false,
      selectedDate: new Date(),
      selectedWeekDay: new Date().getDay(),
      isTomorrow: false,
      isToday: false,
      isPreOrder: false,
      isNewUser: true,
      isStore: false,
      order: {
        offerCode: '',
        selectedOffer: {},
        selectedOffer2: {},
        offerId: '',
        offerValue: 0,
        cashbackValue: 0,
        cashbackValue2: 0,
        offerCode2: '',
        offerId2: '',
        offerValue2: 0,
        restId: '',
        restName: '',
        restLogo: '',
        maxTime: 0,
        itemCost: 0,
        deliveryCharge: 0,
        usageFee: 0,
        taxes: 0,
        serviceFee: 0,
        grandTotal: 0,
        items: [],
      },
      offerCode: '',
      usingWallet: false,
      selectedOffer: null,
      offerId: '',
      offerSuccess: '',
      offerErrorText: '',
      offerValue: 0,
      cashbackValue: 0,
      offerInd: null,
      isOfferApplied: false,
      isOfferError: false,
      isFreeDelivery: false,
      offerCode2: '',
      selectedOffer2: null,
      offerId2: '',
      offerSuccess2: '',
      offerErrorText2: '',
      offerValue2: 0,
      balance: 0,
      cashbackValue2: 0,
      offerInd2: null,
      isOfferApplied2: false,
      isOfferError2: false,
      isFreeDelivery2: false,
      deliveryInstructions: '',
      defaultCard: null,
      toggleDelivery: true,
      tipPercent: 0,
      dynamicDelivery: false,
      additionalDelivery: 0,
      normalDelivery: 0,
      additionalDeliveryPercentage2: 0,
      reasonTitle: '',
      reasonDescription: '',
      isStore2: false,
      showMap: false,
      currenciesList: [],
      currenciesList3: [],
      currenciesList4: [],
      currenciesList5: [],
      currenciesList6: [],
      currenciesList7: [],
      currenciesList8: [],
      currenciesList2: [],
      vh: window.innerHeight * 0.01,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    this.setState({ vh: vh });
    // We listen to the resize event
    window.addEventListener('resize', () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      this.setState({ vh: vh });
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  render() {    
    return (
      <>
	  <div style={{margin:'20px'}}>
        <p style={{marginTop:'0px', marginBottom:'14px', textAlign:'center', lineHeight:'36px', backgroundColor:'#ffffff'}}>
		<span style={{fontFamily:'Arial', fontSize:'24px', color:'#212529', fontWeight: '700'}}>Términos y Condiciones de uso Cliente</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Los presentes Términos y Condiciones de Uso (términos de uso) regulan la forma en que puedes utilizar y utilices nuestro sitio de internet: Appeptito.store (el “Sitio”), mismo que es un sitio “Appeptito” (Yesica Flores Santamaria) es el nombre comercial debidamente registrada y constituida conforme a las leyes de los Estados Unidos Mexicanos, con domicilio para oír y recibir notificaciones en Encinos 27, Casa Blanca, Metepec, C.P. 52175, Edo de Méx; en su carácter de responsable tratará los datos personales del titular, y su uso se rige por los presentes términos y el aviso de privacidad que puedes consultar en Appeptito.store</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Así mismo regula la relación contractual de carácter comercial que vincula a los Consumidores que acceden y/o usan El Sitio con el o los Operadores (negocios) y Empresa Logística (repartidores). Todo lo anterior incluyendo políticas de cancelación.</span>
		</p>
		<p style={{marginTop:'14px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>1. ACEPTACIÓN</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>El uso del Sitio implica tu aceptación de los presentes términos de uso y el aviso de privacidad de “Appeptito”.</span><span style={{fontFamily:'Arial'}}>&#xa0; </span><span style={{fontFamily:'Arial'}}>Si no estás de acuerdo con estos términos de uso o con la política de privacidad mencionada, por favor abandona el sitio.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>La lectura detenida de los presentes términos de uso consumidor y el aviso de privacidad quedan bajo tu más estricta responsabilidad. El ingreso y/o uso del Sitio, esporádica o constante, implica la manifestación expresa de lectura detenida y aceptación de los referidos términos de uso consumidor y el aviso de privacidad.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Estos términos de uso sustituyen expresamente los acuerdos o compromisos previos celebrados entre las partes. Appeptito podrá modificar los Términos y Condiciones relativos a los Servicios cuando lo considere oportuno. Las modificaciones serán efectivas después de la publicación por parte de Appeptito de dichos términos de uso actualizadas en el Sitio o las políticas modificadas o condiciones suplementarias sobre el Servicio aplicable. Su acceso, uso esporádico o continuado de los Servicios, después de dicha publicación, releva a Appeptito cualquier tipo de comunicación a la que esté o se obligue Appeptito y constituye su consentimiento a vincularse por los términos de uso y sus modificaciones, así como a lo que implique la nueva relación contractual a quienes acceden y/o usan el Sitio virtual con el o los Operadores (negocio) y Empresa Logística (repartidor).</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Así mismo, se expide que partir de la autorización que se le otorga con la aceptación de los términos de uso del Sitio, no usará ni llevará a cabo, a través de la plataforma “Appeptito”, sus afiliadas o subsidiarias, ninguno tipo de acto delictivo o fraudulento que se encuentre sancionado y/o penado por la ley mexicana y cualquier tratado internacional aplicable firmado por el Estado Mexicano, en pena de incurrir en investigaciones civiles o penales, que acarreen las correspondientes sanciones. Así mismo, Appeptito autoriza que se investigue en cualquier momento su actividad dentro de la plataforma, solicitándole proveer cualquier tipo de información que sea requerida a fin de dar por superado el hecho.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Derivado de lo anterior, aceptas expresamente que las responsabilidades civiles, administrativas, penales, fiscales o de protección al consumidor son exclusivas de los operadores y/o empresas logísticas, por lo que la aceptación de los presentes términos de uso, así como de la política de privacidad, se establece como la manifestación expresa de que no te reservas acción o derecho alguno, nacional o internacional, que ejercer en contra de Appeptito, en materia civil, administrativa, penal, fiscal, laboral o de protección al consumidor.</span>
		</p>
		<p style={{marginTop:'14px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>2. DEFINICIONES</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Appeptito: Encargado de administrar operativa y funcionalmente el Sitio. Representado para los efectos del presente contrato o de los términos de uso por la persona física designada.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Cookies: Cadenas de texto enviadas virtualmente que son almacenadas por el uso de la Plataforma Appeptito, para la consulta de las actividades y preferencias de los usuarios, mismas que podrán ser remitidas al consumidor conforme a lo descrito en la política de privacidad.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Comercio Electrónico: Comprende el envío, transmisión, recepción, almacenamiento de mensajes de datos por vía electrónica. Las dudas que surjan respecto de la eficacia y validez de los mensajes de datos y demás actividades vinculadas al comercio electrónico se interpretarán de conformidad con el Capítulo VIII BIS de la Ley Federal de Protección al Consumidor.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Consumidor(es): Quien(es) accede(n) y/o usa(n) el Sitio para celebrar por medio de éstas un Contrato de pedido remunerado celebrado por medios electrónicos (pedido).</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Contrato de pedido remunerado celebrado por medios electrónicos (pedido): Acuerdo de voluntades celebrado por el Consumidor con el Operador y la Empresa Logística, con el cual el Consumidor solicita, a través del Sitio, al Operador la producción de bienes, servicios o artículos de consumo (pedido) y a la Empresa Logística su entrega o repartición, obligándose este último a cumplir con dicho pedido por su cuenta y riesgo, así como la del Consumidor, previa remuneración por parte del Consumidor mediante el motor de pagos como contraprestación o en dinero en efectivo, pesos mexicanos.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Datos Personales: Los definidos por la Ley Federal de Protección de Datos Personales en Posesión de Particulares, su Reglamento y la normativa relacionada.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Empresa Logística: Persona física o moral que acepta realizar la entrega o repartición del Contrato de pedido remunerado celebrado por medios electrónicos (pedido) al Consumidor, solicitado por dicho Consumidor a través del Sitio al Operador, con el uso operativo que estime conveniente o a través del ser humano con el que haya generado una relación contractual civil, mercantil o laboral. La Empresa Logística reconoce que realizará lo necesario por cuenta y riesgo propio, así como del Consumidor.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Interacción en la Plataforma: Posibilidad de los Consumidores para conocer los productos o servicios exhibidos y la publicidad puesta a disposición, en el Sitio, por Appeptito, así como la o las remuneraciones, efectuar un Contrato de pedido remunerado celebrado por medios electrónicos (pedido) o cancelarlos, conforme a las políticas de cancelación publicadas en el Sitio.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Facultad del Operador y La Empresa Logística de Aceptar, Rechazar y gestionar cancelaciones de Contrato de pedido remunerado celebrado por medios electrónicos (pedido), conforme a las políticas de cancelación publicadas en el Sitio, previa remuneración mediante el motor de pagos o en efectivo. </span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Mayor de edad: Persona física reconocida como tal por las Leyes de los Estados Unidos Mexicanos o sus Estados Libres y Soberanos integrantes.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Mensajes de Datos: La información generada, enviada, recibida, almacenada o comunicada por medios electrónicos, ópticos o similares, como pudieran ser, entre otros, el Sitio, el Intercambio Electrónico de Datos (EDI), Internet, el correo electrónico, notificaciones push, chat in-app, sms y mms.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Motor de Pagos: Funcionalidad que permite la realización de pagos o remuneraciones, por parte de los Consumidores directamente al operador , a través de medios electrónicos, utilizando plataformas tecnológicas (software), como contra prestación de un Contrato de pedido remunerado celebrado por medios electrónicos (pedido).</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Operador (negocio): Persona Física o Moral que se responsabiliza, de conformidad con el contrato y/o los términos de uso que le aplican, así como con la aceptación que emitió, y produce bienes, servicios o artículos de consumo exhibido(s) a través del Sitio, para permitir que la Empresa Logística cumpla sus obligaciones y el Consumidor reciba lo acordado en el Contrato de pedido remunerado celebrado por medios electrónicos (pedido).</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Publicidad: Es toda forma de comunicación realizada por Appeptito, mediante el Sitio, con el fin de brindar información sobre productos, actividades comerciales y comunicar estrategias o campañas publicitarias o de mercadeo, propias o de terceros; realizada como mecanismo de referencia y no como oferta pública.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>&#xa0;</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Producto(s): Exclusivamente aquellos bienes, servicios o artículos de consumo exhibido(s) a través del Sitio.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Sitio (servicio): Aplicación web y móvil administrada por Appeptito, que permite la concurrencia en un mercado de Consumidores, Operadores y Empresa Logísticas para que por medio de un Contrato de pedido remunerado celebrado por medios electrónicos (pedido) el Consumidor solicite al Operador la producción de bienes, servicios o artículos de consumo (pedido) y a la Empresa Logística su entrega o repartición.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Ventanas Emergentes (Pop-Ups): Ventana o aviso de internet que emerge automáticamente en cualquier momento cuando se utiliza el Sitio, especialmente utilizado para la formalización del Contrato o Contratos de pedido remunerado celebrado por medios electrónicos entre Consumidores, Operadores y Empresa Logística.</span>
		</p>
		<p style={{marginTop:'14px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<strong><span style={{fontFamily:'Arial'}}>&#xa0;</span></strong>
		</p>
		<p style={{marginTop:'14px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<strong><span style={{fontFamily:'Arial'}}>Términos y Condiciones de uso Cliente</span></strong>
		</p>
		<p style={{marginTop:'14px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>REGISTRO</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Algunas funcionalidades de nuestro sitio requieren que inicies sesión con tu cuenta de Correo Electrónico, Cuenta de Google o Perfil de Facebook. La información que nos proporcionas para ingresar se gestiona de conformidad con lo establecido en nuestra política de privacidad. Si tienes alguna duda, por favor consulta nuestra política en </span>
			<a href="http://Appeptito.store" style={{textDecoration:'none'}}>
				<u><span style={{fontFamily:'Arial', color:'#0563c1'}}>Appeptito.store</span></u>
			</a>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Los Consumidores usan como referencia para su compra, los Productos que se encuentran exhibidos en el Sitio, teniendo como condición indispensable la creación de una cuenta de usuario (en adelante la “Cuenta de Usuario”), donde se solicitarán datos como nombre, fecha de nacimiento, teléfono, dirección, dirección de correo electrónico; esta información se utiliza para (i) la plena identificación de los Consumidores que pretenden efectuar un Contrato de pedido remunerado celebrado por medios electrónicos, para (ii) el cumplimiento de la Compraventa, para (iii) la prevención de fraudes, para (iv) vincular al Consumidor con el Operador o la Empresa Logística y en general para los fines definidos del Manejo de información, conforme a la política de privacidad.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>El uso de la Cuenta de Usuario es personal e intransferible, por lo cual los Consumidores no se encuentran facultados para ceder los datos de validación para el acceso a la Plataforma. En caso de olvido de los datos de validación o de usurpación de éstos, es obligación del Consumidor informarlo al Operador a través de la opción “olvidé mi contraseña” o a través de comunicación enviada al correo electrónico: contacto@titodelivery.online</span>
		</p>
		<p style={{marginTop:'14px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>NORMAS DE CONDUCTA</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Para asegurar una sana convivencia entre las personas que utilizan el Sitio, en todo momento deberás conducirte conforme a los siguientes principios:</span>
		</p>
		<ul type="disc" style={{margin:'0px', paddingLeft:'0px'}}>
			<li style={{marginLeft:'32.12px', textAlign:'justify', lineHeight:'normal', paddingLeft:'7.48px', fontFamily:'serif', fontSize:'12px', backgroundColor:'#ffffff'}}>
				<span style={{fontFamily:'Arial'}}>Trata con respeto a los demás.</span>
			</li>
			<li style={{marginLeft:'32.12px', textAlign:'justify', lineHeight:'normal', paddingLeft:'7.48px', fontFamily:'serif', fontSize:'12px', backgroundColor:'#ffffff'}}>
				<span style={{fontFamily:'Arial'}}>No insultes ni ofendas a terceros.</span>
			</li>
			<li style={{marginLeft:'32.12px', textAlign:'justify', lineHeight:'normal', paddingLeft:'7.48px', fontFamily:'serif', fontSize:'12px', backgroundColor:'#ffffff'}}>
				<span style={{fontFamily:'Arial'}}>No difundas información falsa.</span>
			</li>
			<li style={{marginLeft:'32.12px', textAlign:'justify', lineHeight:'normal', paddingLeft:'7.48px', fontFamily:'serif', fontSize:'12px', backgroundColor:'#ffffff'}}>
				<span style={{fontFamily:'Arial'}}>No incites a la violencia o a la comisión de algún delito o infracción.</span>
			</li>
			<li style={{marginLeft:'32.12px', textAlign:'justify', lineHeight:'normal', paddingLeft:'7.48px', fontFamily:'serif', fontSize:'12px', backgroundColor:'#ffffff'}}>
				<span style={{fontFamily:'Arial'}}>No te hagas pasar por otra persona.</span>
			</li>
			<li style={{marginLeft:'32.12px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', paddingLeft:'7.48px', fontFamily:'serif', fontSize:'12px', backgroundColor:'#ffffff'}}>
				<span style={{fontFamily:'Arial'}}>No infrinjas derechos de autor o de propiedad intelectual.</span>
			</li>
		</ul>
		<p style={{marginTop:'14px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>CANCELACIÓN DE CUENTA DE USUARIO</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>En caso de que a criterio de Appeptito se considere que no has respetado los términos de uso consumidor del Sitio, se reserva el derecho de suspender cualquier servicio parcial o totalmente. En consecuencia, estás de acuerdo en que tu aceptación a los términos de uso consumidor y la utilización del sitio constituye una autorización expresa a Appeptito, para poder suspender los servicios respecto de usted o, en general, dejar de ofrecer o denegar el acceso a los Servicios o cualquier parte de ellos, en cualquier momento y por cualquier motivo de manera unilateral.</span>
		</p>
		<p style={{marginTop:'14px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>OBJETO DE LOS TÉRMINOS Y CONDICIONES:</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Los presentes términos de uso consumidor regulan la autorización de uso que otorga Appeptito a los Consumidores, para que éstos ingresen a el Sitio, se informen sobre los bienes, servicios o artículos de consumo para que sean utilizados como referencia y puedan solicitar un Contrato de pedido remunerado celebrado por medios electrónicos.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Appeptito, a través del el Sitio realiza de manera limitativa, las siguientes acciones: (i) exhibe diferentes productos de consumo de forma publicitaria para que puedan servir de referencia a los Consumidores; (ii) facilita el encuentro entre Consumidores, Operadores y Empresa Logística para la realización de un Contrato de pedido remunerado celebrado por medios electrónicos; (iii) permite el uso de la Motor de Pagos, sin que en este proceso implique vínculo alguno con Appeptito, de conformidad con la definición de Contrato de pedido remunerado celebrado por medios electrónicos; (iv) sirve de medio de envío de comunicaciones entre los Consumidores Operadores y Empresa Logística.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>La celebración de la relación contractual entre Consumidores, Operadores y Empresa Logística, se da con Consumidores que se encuentren en el territorio nacional o que estando en el extranjero solicitan la gestión de un Contrato de pedido remunerado celebrado por medios electrónicos, que debe ser entregado o repartido en Los Estados Unidos Mexicanos, previo pago o remuneraciones mediante el Motor de Pago o en dinero en efectivo. durante la operación de la versión Beta, al momento de la recepción de los Productos.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>A través del Sitio se exhiben Productos y mediante un Contrato de pedido remunerado celebrado por medios electrónicos encarga el Consumidor a los Operadores o Empresa Logísticas un pedido, siendo que los primeros buscan satisfacer una necesidad privada, personal o familiar, y en ningún momento pretenden la comercialización, reventa o cualquier otro tipo de transacción comercial o interés con los productos adquiridos.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Los comentarios que hagas respecto de los posts o dentro de los foros de nuestro sitio también son considerados contenidos. Por esto, al publicar cualquier tipo de contenido en nuestro sitio, estás de acuerdo en otorgar una licencia no exclusiva, libre del pago de regalías, a nivel global y por tiempo indefinido para el uso, comunicación pública, reproducción, distribución y transformación de los contenidos que subas a nuestro sitio. En consecuencia, el envío de comentarios a nuestro sitio, representa tu autorización expresa para su publicación, reproducción, distribución, exhibición y comunicación pública bajo la licencia referida.</span>
		</p>
		<p style={{marginTop:'14px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>LICENCIA</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Sujeto al cumplimiento de los presentes términos de uso consumidor, Appeptito otorga a los Consumidores una autorización limitada, no exclusiva, revocable, no transferible para: (i) el acceso y uso del Sitio o la App en el dispositivo personal del Consumidor sólo con relación con su uso de los Servicios; y (ii) el acceso y uso de cualquier contenido, información y material relacionado que pueda ponerse a disposición a través del Sitio en cada caso solo para su uso personal, no comercial. Appeptito y sus licenciantes se reservan cualquier derecho que no haya sido expresamente otorgado por los presentes términos de uso consumidor.</span>
		</p>
		<p style={{marginTop:'14px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>LIMITACIÓN DE RESPONSABILIDAD</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Appeptito no es responsable de los contenidos publicados por los consumidores del Sitio. En este sentido, al utilizarlos, te obligas a ser el único responsable frente a terceros respecto de los contenidos que sean subidos con tu cuenta. En consecuencia, te obligas a sacar en paz y a salvo a Appeptito, sus empleados, funcionarios, accionistas, empresas subsidiarias, proveedores y clientes, en caso de que surja una controversia respecto de los contenidos que subas con tu cuenta de usuario.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>El Sitio se proporcionan sin garantía alguna, es decir, tal cual. Por lo anterior, Appeptito no será responsable de ningún daño causado a los equipos de los usuarios del Sitio, a la información que intercambien o suban al Sitio y en general cualquier daño que pueda relacionarse, directa o indirectamente, con el uso del Sitio.</span>
		</p>
		<p style={{marginTop:'14px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>&#xa0;</span>
		</p>
		<p style={{marginTop:'14px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>LOS SERVICIOS</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>El Operador exhibe a través de La Plataforma productos de consumo como alimentos, bebidas, productos de aseo personal y productos de aseo general, que están a disposición de los Consumidores para su conocimiento general. Esta comunicación de productos sirve como referencia a los Consumidores para poder usar La Plataforma como medio para efectuar un Contrato de pedido remunerado celebrado por medios electrónicos.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Los Servicios se ponen a disposición solo para su uso personal, no comercial. Cualquier Consumidor que acceda y/o use la Plataforma, reconoce que los Servicios de Appeptito no incluyen servicios de venta de productos, de entrega a domicilio o de logística, en cuanto la relación contractual se constituye directamente entre el Consumidor y el Operador o Empresa Logística, quien actúa de manera independiente y bajo ninguna circunstancia puede ser entendido como un empleado de Appeptito o de sus afiliadas.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>El uso de la Plataforma lo realiza el Consumidor como persona capaz y mayor de edad, y manifestando que, para la celebración del Contrato de pedido remunerado celebrado por medios electrónicos con los Operadores o Empresa Logísticas, cuenta con plena capacidad legal para ser sujeto de derechos y obligaciones, calidades que refrenda al momento de generar su registro.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>El Consumidor reconoce expresamente mediante la aceptación de los presentes términos de uso consumidor de la Plataforma que Appeptito no será responsable en ningún momento de cualquier producto que los Consumidores soliciten mediante un Contrato de pedido remunerado celebrado por medios electrónicos a través de la Plataforma. Asimismo, Appeptito tampoco podrá observar y verificar el correcto funcionamiento de la Plataforma con relación a cualquier otro producto distinto a aquellos exhibidos en la Plataforma.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Al momento de la notificación del servicio, el Operador podrá declinar el Contrato de pedido remunerado celebrado por medios electrónicos (rechazar el servicio), de forma unilateral sea por la imposibilidad de conseguir los productos o por condiciones de tiempo y ubicación. En caso de rechazo o cancelación conforme a las políticas de cancelación publicadas en el Sitio, el Sitio “Appeptito” avisará mediante las mismas al Consumidor, restituyendo en un término que no exceda de 72 horas el pago o retribución.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>El perfeccionamiento del Contrato de pedido remunerado celebrado por medios electrónicos se da en el momento en que el Operador o la Empresa Logística acepta el Contrato de pedido remunerado celebrado por medios electrónicos efectuado por el Consumidor mediante el Sitio o la App.</span>
		</p>
		<p style={{marginTop:'14px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>&#xa0;</span>
		</p>
		<p style={{marginTop:'14px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>CAPACIDAD</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Manifiesta el Consumidor que la relación contractual que se puede llegar a generar por el uso de la Plataforma será directamente y exclusivamente con los Operadores y con las Empresas Logísticas, y consistirá en un Contrato de pedido remunerado celebrado por medios electrónicos, en el cual el Consumidor es el mandante.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>En virtud de las condiciones establecida en el Código Civil Federal, los Consumidores al momento de la creación de la Cuenta de Usuario, manifiestan expresamente tener capacidad suficiente para celebrar el tipo de contrato de mandato, que se puede o podría llegar a configurar accediendo y/o usando la Plataforma; y no obstante lo anterior, los menores de edad de acuerdo con la ley aplicable, no están facultados para hacer uso en ningún momento de la Plataforma provocando así la nulidad de todo mandato generado en la Plataforma en caso de contravención a lo que aquí establecido.</span>
		</p>
		<p style={{marginTop:'14px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>OBLIGACIONES DEL CONSUMIDOR</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Con la aceptación de los presentes términos de uso consumidor de la Plataforma, el Consumidor se obliga a:</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>(1) Suministrar información veraz y fidedigna al momento de crear su propia Cuenta de Usuario.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>(2) Abstenerse de transferir a terceros los datos de validación (nombre de usuario y contraseña).</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>(3) Abstenerse de acceder y/o usar la Plataforma para realizar actos contrarios a la moral, al orden público, a las buenas costumbres o actos o hechos ilícitos en contra del Operador, la Empresa Logísticas o de terceros.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>(4) Pagar mediante el Motor de Pagos al Operador o bien, en efectivo a la Empresa Logística durante el funcionamiento del Contrato de pedido remunerado celebrado por medios electrónicos.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>(5) Informar inmediatamente al Operador en caso de olvido o usurpación de los datos de validación.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>(6) Abstenerse de realizar conductas atentatorias del funcionamiento de la Plataforma.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>(7) Abstenerse de requerir a las Empresas Logísticas cualquier otro producto cuya comercialización, venta o distribución por teléfono, correo, internet o cualquier otro medio de comunicación sea prohibida por la legislación aplicable.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>(8) Abstenerse de suplantar la identidad de otros Consumidores.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>(9) Abstenerse de descifrar, descompilar o desensamblar, apropiarse de cualquier elemento de la Plataforma o de cualquiera de sus partes.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>(10) Habilitar la utilización de ventanas emergentes durante la operación de la Plataforma.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>(11) En general, todas aquellas conductas necesarias para la ejecución del Operador y/o Empresa Logística jurídico, como i) la recepción de los productos solicitados, ii) exhibir la identificación en caso de compra de productos de uso restringido, iii) verificar al momento de la validación que los Productos seleccionados si corresponden a los necesitados, iv) informarse sobre las instrucciones de uso y consumo de los Productos, pagar el precio al Operador y/o Empresa Logística.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>12) Sacar en paz y salvo a Appeptito de cualquier reclamación de naturaleza jurídica en su contra y a asumir de manera personal, de manera enunciativa más no limitativa, las consecuencias relativas a la licitud, calidad, imagen, presentación, tamaño, color, precio, etc., de aquel(los) producto(s) distintos al (los) Producto(s) exhibidos en la Plataforma y que sean requeridos por los Consumidores a través de la Plataforma. V) En caso de requerir factura, debe informarle al Operador para que le proporcione la factura por el costo del (los) producto(s) brindado (s). Si requiere factura del (los) Servicio (s) que adquirió, debe informar al correo contacto@titodelivery.online para que se le proporcione los datos de ticket de compra y pueda obtener la factura del Servicio el cual adquirió.</span>
		</p>
		<p style={{marginTop:'14px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<strong><span style={{fontFamily:'Arial'}}>Términos y Condiciones para Restaurantes Socios</span></strong>
		</p>
		<p style={{marginTop:'14px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>SERVICIOS DE ENTREGA</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Usted reconoce y acepta que la prestación de Servicios de Entrega a los Usuarios crea una relación comercial directa entre el Usted y el Usuario.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Usted será el único responsable de cualquier obligación o responsabilidad con los Usuarios, los destinatarios de la entrega u otros terceros que surjan de la prestación de los Servicios de Entrega.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>El Usuario que acceda, navegue o utilice el sitio web de Appeptito o de la App, podrá disfrutar de los servicios de entrega en línea de productos, alimentos y bebidas a través de los Servicios, el Usuario podrá disfrutar de los Servicios de Entrega y recibir los Productos proporcionados por Usted, a través de las solicitudes y la información del pedido a través de la el sitio web de Appeptito o de la App.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>A través de Appeptito, Usted obtendrá información sobre el pedido del “Usuario”, así como las instrucciones del Usuario, y obtendrá ingresos proporcionales del pedido a través del servicio de recolección y distribución de tarifas de Appeptito.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Usted reconoce y acepta que es el único responsable de garantizar la calidad sanitaria de los productos, alimentos y/o bebidas, que hacen que dichos artículos sean aptos y adecuados para el consumo humano.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Al aceptar el pedido de entrega Appeptito le proporcionará la información del pedido y cualquier especificación del pedido por parte de los Usuarios.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>La relación entre Usted y Appeptito es exclusivamente la de un proveedor de servicios interno o externo.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Usted acuerda que al hacer uso de la aplicación de Appeptito para ofrecer productos, alimentos y bebidas, Usted hará buen uso de la el sitio web de Appeptito o su App. En consecuencia, Usted conviene que no ofrecerá empaques u otro tipo de materiales promocionales con la marca de otras plataformas tecnológicas similares a Appeptito en los pedidos de sus Productos que los usuarios realicen a Usted a través de la aplicación tecnológica de Appeptito.</span>
		</p>
		<p style={{marginTop:'14px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>OPCIÓN DE ENTREGAS CON PERSONAL PROPIO</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Para la opción de Entregas con su Personal Propio: Usted reconoce y acepta que Usted, y cada persona vinculada a Usted, que realice entregas son los únicos responsables de:</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>(a) cualquier responsabilidad que surja frente a un Cliente o de un tercero relacionado con los servicios de entrega prestados por Usted y su personal, y</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>(b) que ha tomado las precauciones que sean necesarias, razonables y adecuadas relacionadas a las leyes aplicables de acuerdo a la legislación que es aplicable en relación con su personal.</span>
		</p>
		<p style={{marginTop:'14px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>OBLIGACIONES</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Es responsabilidad de Usted mantener actualizado todo el hardware o software del dispositivo móvil para que se ajuste a los requisitos actuales de Appeptito.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Appeptito no es responsable de ningún problema que pueda surgir cuando Usted utilice una versión no actualizada o la más reciente de Appeptito, y / o use Appeptito en cualquier dispositivo móvil que no cumpla con los requisitos vigentes de Appeptito.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Usted acuerda que Appeptito puede actualizar los requisitos del hardware y software del teléfono móvil de Appeptito periódicamente.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Al utilizar los Servicios, Usted también acuerda lo siguiente:</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>i. Usted utilizará los Servicios y la Plataforma de Appeptito de buena fe,</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>ii. Usted utilizará los Servicios y la Plataforma de Appeptito sólo para uso personal y no revenderá a terceros;</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>iii. Usted no utilizará los Servicios y la Plataforma de Appeptito para ningún propósito ilegal;</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>iv. Usted no utilizará los Servicios y la Plataforma de Appeptito para acosar u obstruir a otros o causar inconvenientes a otros;</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>v. Usted no afectará el funcionamiento normal de la red</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>vi. Usted no intentará dañar los Servicios y la Plataforma de Appeptito; y</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>vii. Usted cumplirá con todas las leyes aplicables del país / región, provincia y / o ciudad en la que Usted utiliza la aplicación o los Servicios.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>En caso de hacer de no observar y dar cumplimiento a los puntos mencionados anteriormente, así como el hacer un mal uso o utilizar de manera distinta, o contrario a lo estipulado en estos Términos y Condiciones, Appeptito se reserva el derecho a cancelarle el uso de el sitio web de Appeptito o App.</span>
		</p>
		<p style={{marginTop:'14px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>RESPONSABILIDAD</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Appeptito proporcionará los Servicios de Entrega de acuerdo con las normas de servicio acordadas, y las leyes aplicables en México.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Appeptito no representa ni garantiza la seguridad de ningún artículo proporcionado a los Usuarios para los Servicios de Entrega.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Appeptito renuncia expresamente a cualquier responsabilidad por cualquier acto u omisión de Usted, cualquier Usuario, u otro tercero.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Appeptito ejecuta un esfuerzo razonable para garantizar la exactitud de la información aparecida en Appeptito, no garantizando que dicha información esté libre de errores, defectos, software malintencionados o virus.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Appeptito no será responsable de ningún daño que resulte del uso de, o cualquier incapacidad de uso del web de Appeptito. Además, Appeptito no será responsable por ningún daño que resulte del uso de, o cualquier incapacidad para utilizar las herramientas de comunicación electrónica del sitio web de Appeptito, incluidos, sin limitación, cualquier daño causado por un fallo en la transmisión, mal funcionamiento de Internet o retraso de la comunicación electrónica, interceptación o manipulación de la comunicación electrónica por un tercero o programa de computadora utilizado para la comunicación electrónica y la transmisión de virus, falla de energía, huelgas o cualquier otra disputa laboral, disturbios, insurrección, agitación, terremoto, incendio, inundación, tormenta, explosión, guerra, o cualquier situación originada por caso Fortuito o de Fuerza Mayor.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>En caso de que se presente un evento de Fuerza Mayor, la parte afectada podrá suspender temporalmente el cumplimiento de las obligaciones a su cargo hasta que el efecto de dicha causa de Fuerza Mayor cese, y no incurrirá en incumplimiento; en el entendido, no obstante que dicha parte hará su mejor esfuerzo para resolver dicha causa y mitigar las pérdidas. Fuerza mayor significa cualquier causa impredecible e inevitable (aun cuando sea previsible) fuera del control de las partes que impida, afecte o demore el cumplimiento por una parte de todas o cualquiera de sus obligaciones conforme al presente instrumento.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Appeptito no será responsable de ningún daño indirecto, incidental, especial, ejemplar, punitivo o consecuente, incluida la pérdida de uso de beneficios, la pérdida de datos, lesiones personales o daños a la propiedad relacionada con cualquier otro de los Servicios, o de cualquier otro modo, independientemente de la negligencia (ya sea activa, afirmativa, única o concurrente) de Appeptito, incluso si Appeptito ha sido informado de la posibilidad de tales daños.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Appeptito no garantiza la exactitud, integridad, adecuación y confiabilidad de la información y el contenido del sitio web de Appeptito, incluidos, entre otros, textos, imágenes, datos, opiniones, páginas web o enlaces, a pesar de sus esfuerzos por proporcionar información precisa, veraz e información exhaustiva en la medida de lo posible.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Appeptito no se responsabiliza de ningún error u omisión, ni otorga ninguna garantía explícita o implícita. Excepto por lo expresamente establecido en estos Términos y Condiciones, todas las garantías, Condiciones y representaciones expresadas o implícitas o indicadas de otro modo por Appeptito (incluyendo, sin limitación, garantías de calidad satisfactoria, aptitud para el propósito o habilidad y cuidado) quedan excluidas por esta estipulación cuando la legislación aplicable así lo permita.</span>
		</p>
		<p style={{marginTop:'14px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>PUBLICIDAD</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Usted se compromete a incluir en su publicidad difundida por medios impresos, electrónicos, digitales y de internet el logo del servicio Appeptito con fines promocionales. Asimismo, tratará el servicio de Appeptito a la par que cualquier otra forma de Servicio de Entregas que se ofrezcan en sus puntos de venta o en cualquier lugar en el que se integren sus actividades o servicios, incluidos sus sitios web o aplicaciones móviles. Esto incluye, como mínimo, condiciones iguales o mejores en comparación con otras plataformas de Servicio de Venta o Entrega que ofrezca en sus puntos de venta respecto de la ubicación del logotipo, la posición en cualquier punto de venta. Usted acepta mostrar la marca de los servicios de entrega “Appeptito” al menos de manera tan prominente y positiva como se muestran las demás Plataformas de Venta o Entrega.</span>
		</p>
		<p style={{marginTop:'14px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>AUTORIZACIÓN Y LICENCIA</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Appeptito otorga a Usted una licencia limitada, sin pago de regalías, no exclusiva, intransferible, inalienable, sin derecho a sublicenciar, para usar la Aplicación de Proveedor de Appeptito para instalar y utilizar la Aplicación Appeptito únicamente con el propósito de que usted realice la venta de productos, alimentos y bebidas y/o Servicios que realice.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Asimismo, Usted no deberá: (1) enviar spam o mensajes duplicados o no solicitados en violación de las leyes aplicables; (2) enviar o almacenar material violatorio, obsceno, amenazante, difamatorio o que de otra manera sea ilegal o ilícito, incluyendo material que infrinja los derechos de privacidad de terceros; (3) enviar o almacenar material que contenga virus de software, gusanos, caballos troyanos u otro código informático, archivos, scripts, agentes o programas perjudiciales; (4) interferir con o afectar la integridad o el funcionamiento de los sitios web de Appeptito, sus aplicaciones o los Servicios o los datos contenidos en los mismos; ni (5) intentar obtener acceso no autorizado al sitio web de Appeptito o App.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Appeptito tendrá derecho a investigar y promover un juicio por cualquiera de los incumplimientos antes mencionados en la mayor medida permitida por la ley. Appeptito podrá iniciar y participar en las demandas en contra de cualquier Restaurante que haya incumplido los presentes Términos y Condiciones y asistir a las autoridades encargadas del orden público en las acciones que lleven a cabo contra actos ilícitos del Restaurante. Si Appeptito determina que cualquier contenido infringe los presentes Términos y Condiciones o de otra manera perjudica al sitio web de Appeptito, Appeptito y / o los Servicios o aplicaciones relacionadas, Appeptito se reserva el derecho de eliminar o prohibir el acceso a dicho contenido en cualquier momento sin previa notificación al Restaurante.</span>
		</p>
		<p style={{marginTop:'14px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>GENERALIDADES</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>En caso de que Usted tenga algún problema con el sitio web Appeptito y/o considere que alguna parte del presente Contrato no está siendo observada o cumplida por Appeptito o de presentarse cualquier diferencia entre las Partes derivada o relacionada con el presente Contrato, Usted acepta que el proceso a seguir consiste en comunicar dicha situación a través de la sección ubicada en el menú principal de la Aplicación de Proveedor denominada “Ayuda” o en su defecto a través de la página web (O CORREO) Appeptito.store , con el propósito de analizar y resolver el caso concreto.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>El Contrato será obligatorio y se entenderá en beneficio de cada una de las partes y sus respectivos sucesores y cesionarios autorizados.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Cualquier retraso o falla de las partes con relación a la ejecución del Contrato será excusada en la medida en la que dicho retraso o falla sea ocasionado por eventos fuera del control de la parte afectada, incluyendo, sin limitar, decretos y prohibiciones Gubernamentales, actos de fuerza mayor, huelgas o cualquier otro contratiempo laboral, guerra o sabotaje.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Nada de lo pactado en el Contrato se entenderá como una asociación, joint venture, o relación de agencia entre las partes, asimismo, ninguna de las partes podrá celebrar contratos en nombre de la otra, o bien, asumir responsabilidades u obligaciones en representación de la otra sin que medie un contrato separado por escrito entre las partes.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Cada parte será responsable por sus empleados o agentes que participen en la ejecución del Contrato.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>El acuerdo contenido en el presente Contrato constituye el acuerdo único y completo entre ellas respecto del objeto del mismo, por lo que dejan sin efectos a cualquier otro acuerdo, Contrato, compromiso o Contrato verbal o escrito que hubieran celebrado las mismas con anterioridad respecto del objeto y las obligaciones derivadas del mismo.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>El Contrato podrá ser firmado en varios tantos y cada uno será considerado como original, y en conjunto serán considerados como un solo documento. El Contrato podrá ser firmado por cualquiera de las partes en uno o más ejemplares podrán ser enviado por correo electrónico, y las copias de este Contrato que sean firmadas y enviadas en versión PDF tendrán la misma validez y efecto que aquellas copias que hayan sido firmadas en original. Todas las copias, tomadas en conjunto, constituirán un solo instrumento original.</span>
		</p>
		<p style={{marginTop:'14px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<strong><span style={{fontFamily:'Arial'}}>Términos y Condiciones de uso para repartidor “Comisionista”</span></strong>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Antes de utilizar Appeptito, lea estos Términos y Condiciones cuidadosamente. Al utilizar la cuenta de Usuario y la contraseña de Appeptito, el Comisionista puede iniciar sesión en Appeptito. Sin embargo, es posible que el Comisionista no disfrute de los servicios en su totalidad hasta que haya cargado todos los documentos e información requerida, haya sido aprobado por Appeptito y acepte estar sujeto a todos los Términos y Condiciones de Uso de Appeptito.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Si el Comisionista selecciona y utiliza los Servicios, se considerará que el Comisionista ha leído y aceptado en su totalidad todos los términos del presente y está de acuerdo con los Términos y Condiciones, que son legalmente vinculantes entre el Comisionista y la Empresa. El Comisionista deberá observar los siguientes términos de buena fe al utilizar Appeptito. El Comisionista reconoce que la capacidad de proporcionar servicios de logística y/o entrega a través del uso de Appeptito, no establece a Appeptito como proveedor de servicios de logística o entrega o como transportista.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>No existe una relación laboral directa o indirecta entre la Empresa, Appeptito o cualquier entidad relacionada y el Comisionista. La Ley Federal del Trabajo, la Ley de Seguro Social, el Código Penal Federal y Local, y su normativa no serán aplicables en la relación entre la Empresa, Appeptito o cualquier entidad relacionada y el Comisionista. La Empresa, Appeptito y cualquier entidad relacionada no dirigen ni controlan al Comisionista en ningún momento, incluso durante la prestación de los servicios de entrega o el uso y/o mantenimiento de los medios de transporte. El Comisionista se reserva el derecho exclusivo de determinar cuándo, dónde y durante cuánto tiempo utilizará Appeptito.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Appeptito se reserva, el derecho de negarse a proporcionar los Servicios al Comisionista o de rechazar el uso por parte del Comisionista del Sitio de Appeptito de los sitios web, servicios o aplicaciones de Appeptito sin razón alguna.</span>
		</p>
		<p style={{marginTop:'14px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>REGISTRO DEL COMISIONISTA</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>1.1 Para utilizar y registrarse a los Servicios en línea, el Comisionista deberá descargar Appeptito, instalarlo en su dispositivo móvil y completar con éxito el procedimiento de registro. Al registrarse, el Comisionista debe asegurarse de que toda la información proporcionada sea correcta, completa y válida.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>1.2 Para completar el registro, el Comisionista autoriza a Appeptito a realizar una verificación manual y cuidadosa de la documentación provista por el Comisionista. Solo si la resolución de la empresa especializada es positiva y el Comisionista cumple con los demás requisitos, el Comisionista se convertirá en un Usuario registrado de Appeptito.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>1.3 El Comisionista reconoce y acepta que será responsable de toda la actividad que ocurra en su cuenta de usuario (la "Cuenta") y acepta mantener la seguridad y confidencialidad de su Usuario y contraseña en todo momento.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>1.4 El Comisionista reconoce y acepta que una vez que el Comisionista se convierta en Usuario registrado de Appeptito, la Cuenta de servicio de la Empresa se abrirá de forma predeterminada. La Cuenta y la contraseña predeterminadas serán la Cuenta y la contraseña de la Cuenta Appeptito del Comisionista.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>1.5 Al registrarse en Appeptito el Comisionista, acepta recibir correos electrónicos, llamadas telefónicas, notificaciones automáticas relevantes, SMS de Appeptito y autoriza a Appeptito a mostrar su perfil e información de contacto, incluyendo nombre, teléfono, correo electrónico y foto, para mostrar su credibilidad a los USUARIOS/CONSUMIDORES y permitir que los USUARIOS/CONSUMIDORES y Negocios se pongan en contacto.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>1.6 El Comisionista deberá contar con plena capacidad de conformidad con el Código Civil Federal. Una persona menor de 18 años no podrá convertirse en Comisionista.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>1.7 El Comisionista reconoce que la capacidad de proporcionar Servicios de Entrega a través del uso de la Plataforma, no implica a Appeptito como proveedor de entrega, prestador de servicios o como transportista de ningún tipo.</span>
		</p>
		<p style={{marginTop:'14px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>SERVICIOS</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>2.1 El USUARIO/CONSUMIDOR que acceda, navegue o utilice Appeptito o los sitios web de Appeptito podrá disfrutar de los servicios de entrega en línea de productos, alimentos y bebidas ("Productos") según sus necesidades a través de la interacción de información en línea y fuera de línea en tiempo real y la correspondencia efectiva de los recursos fuera de línea a través del análisis de Big Data ("Servicios de Entrega"). A través de los Servicios, el USUARIO/CONSUMIDOR podrá disfrutar de los Servicios de Entrega y recibir los Productos proporcionados por el Comisionista que recibe las solicitudes y la información del pedido a través de Appeptito. A través de Appeptito, el Comisionista puede disfrutar de los Servicios, que incluyen, entre otros, obtener información sobre el pedido del USUARIO/CONSUMIDOR y obtener ingresos proporcionales del pedido a través del servicio de recolección y distribución de tarifas de Appeptito.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>2.2 El Comisionista verá las solicitudes y los pedidos de los Servicios de Entrega que solicite el USUARIO/CONSUMIDOR a través de Appeptito, y Appeptito proporcionará al Comisionista cierta información del Usuario, información del destinatario, información del Negocio en el que el Comisionista deberá recoger los Productos, el tiempo de entrega designado por la Plataforma y las instrucciones del USUARIO/CONSUMIDOR a través de Appeptito, incluidas las ubicaciones de recolección y el destino de los Productos correspondientes que deberán entregarse.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>2.3 El Comisionista reconoce y acepta que es el único responsable de determinar el procedimiento más eficaz, eficiente y seguro para llevar a cabo cada Servicio de Entrega. El Comisionista hará su mayor esfuerzo para almacenar y entregar los Productos de manera segura y a tiempo. El Comisionista será responsable de toda pérdida contaminación o sustancia tóxica relacionada con los Productos, debido a la forma ineficiente para llevar a cabo cada entrega o por tener medidas sanitarias deficientes en la Mochila o caja de Entrega. El Comisionista será responsable de las entregas fuera de tiempo, o si el pedido se entrega a una dirección incorrecta o un destinatario incorrecto.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>La Empresa se reserva, a su entera discreción, el derecho de ajustar o cancelar la Tarifa de entrega del Comisionista correspondiente a un Servicio de Entrega específico, en caso de incumplimiento de estos Términos y Condiciones o cualquier irregularidad o ilegalidad atribuible al Comisionista durante o debido a los Servicios de Entrega (incluyendo, sin limitar: (a) si el Comisionista no completó correctamente un servicio de entrega; (b) si ocurrió un error técnico; (c) el Comisionista no cumplió con una hora específica en la finalización de los Servicios de Entrega; d) el Comisionista no cumpla con el servicio de entrega dentro de un tiempo razonable; (e) si el Comisionista cobra al Usuario por los Servicios de Entrega que no se realizaron o completaron parcial o totalmente; (f) la queja de un Usuario; (g) fraude; (h) robo, abuso de confianza, o cualquier conducta deshonesta, fraudulenta o ilegal.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>2.4 El Comisionista reconoce y acepta que es el único responsable de garantizar que la calidad sanitaria de los productos, alimentos y/o bebidas, que hacen que dichos artículos sean aptos y adecuados para el consumo humano, se conserven, y por ningún motivo pueden ser transportados en vehículos destinados al transporte de pesticidas, nutrientes vegetales, sustancias tóxicas o peligrosas o productos de limpieza corrosivos, en general cual ambiente insalubre o dañino.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>2.5 El Comisionista debe estar debidamente equipado con las herramientas, equipo y otros materiales necesarios, incluidos los gastos correspondientes, que son necesarios para proporcionar los Servicios de Entrega. Este equipo puede incluir un Kit Appeptito que incluye: Caja de Entrega y/o mochila, ropa, etc.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>2.6 Antes de la aceptación del pedido de entrega (“Aceptación de la Orden”), el Comisionista puede estar en el periodo de espera para el pedido, durante el cual la Empresa le proporcionará la información del pedido y cualquier especificación del pedido por parte de los USUARIOS/CONSUMIDORES. De acuerdo con la interacción de la información en línea y fuera de línea en tiempo real y la correspondencia efectiva de los recursos fuera de línea a través del análisis de Big Data, Appeptito enviará la información del pedido al Comisionista, tomando en cuenta el USUARIO/CONSUMIDOR más adecuado y el pedido más cercano al Comisionista. La cooperación entre el Comisionista y la Empresa comienza una vez que acepta el pedido solicitado a través de Appeptito y finaliza cuando los Productos se entregan al USUARIO/CONSUMIDOR ("Periodo de Cooperación"). Durante el resto del tiempo, además del Período de cooperación, la Empresa no requiere y no requerirá que el Comisionista continúe con los Servicios de Entrega.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>El Comisionista, como proveedor independiente de Servicios de Entrega, no proporcionará al Usuario Servicios de Entrega fuera de línea.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>2.7 El Comisionista no revelará, ni divulgará ningún tipo de información personal de ningún USUARIO/CONSUMIDOR o Comisionista al que haya tenido acceso por los Servicios a ninguna de las partes, a menos que sea para el propósito de los Servicios o que los haya obtenido legalmente de otras fuentes, en términos de la legislación aplicable.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>2.8 Los Servicios requieren que el Comisionista envíe a la Empresa cierta información personal. El Comisionista acepta mantener la información personal requerida por Appeptito de forma precisa, completa y actualizada. El incumplimiento de dicha obligación puede resultar en la incapacidad del Comisionista para acceder o utilizar los Servicios.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>2.9 La relación entre el Comisionista y la Empresa es exclusivamente la de un proveedor de servicios independiente. El Comisionista acepta expresamente que: (a) estos Términos y Condiciones no son un acuerdo de empleo, ni generan ninguna relación de empleo o subordinación (incluso desde la perspectiva de la legislación laboral, la legislación fiscal o la ley de seguridad social); y (b) no existe una relación de asociación comercial o de agente entre la Empresa y el Comisionista.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Teniendo en cuenta que la relación entre Appeptito y el Comisionista no estará relacionada laboralmente. Appeptito, o cualquier entidad relacionada no tendrá ninguna responsabilidad/obligación derivada de la relación con el Comisionista, incluidas las obligaciones laborales, de seguridad social, desempleo, cotizaciones de seguros o cualquier otro beneficio que no sea aplicable en una relación de proveedor de servicios independiente.</span>
		</p>
		<p style={{marginTop:'14px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>SERVICIOS DE TRANSPORTE Y ENTREGA</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>3.1 El Comisionista reconoce y acepta que en todo momento:</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>i. Tendrá y mantendrá una licencia de conducir válida, la verificación del vehículo, la tarjeta de circulación y el seguro de vehículo, cuando el transporte y entrega se realice utilizando un automóvil o una motocicleta;</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>ii. Obtendrá todos los permisos y autorizaciones de la autoridad que sean aplicables para proporcionar Servicios de Entrega a los Usuarios;</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>iii. Tendrá el nivel adecuado de capacitación, experiencia y conocimientos para brindar Servicios de Entrega de una manera profesional con el debido cuidado y diligencia;</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>iv. Proveerá Servicios de Entrega con profesionalismo y de acuerdo con los estándares de la industria de Servicios de Entrega;</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>v. Asumirá el costo de todos los equipos, herramientas y otros materiales, asumiendo los gastos correspondientes, que sean necesarios para proporcionar los Servicios de Entrega, incluido el Kit Appeptito y el uniforme;</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>vi. Mantendrá la calidad del servicio, la entrega correcta de los Productos y la seguridad al momento de conducir;</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>vii. Mantendrá las medidas sanitarias adecuadas para evitar pérdidas, contaminación o sustancias tóxicas en los Productos.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>viii. Compartirá su ubicación GPS con la Empresa Appeptito, de conformidad y para los efectos de lo previsto en los apartados 5, 5.1, 5.2, 5.3, 5.4, 5.5, 5.6, 7, 7.1, 7.2, 7.3, 7.4, 7.5 y 21 de los presentes términos y condiciones.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>ix. Permitirá el acceso a la cámara del teléfono que registre ante la empresa, de conformidad y para los efectos de lo previsto en los apartados 5, 5.1, 5.2, 5.3, 5.4, 5.5, 5.6, 7, 7.1, 7.2, 7.3, 7.4, 7.5 y 21 de los presentes términos y condiciones.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>El Comisionista reconoce y acepta que la Empresa se reserva el derecho de negarse a proporcionar los Servicios al Comisionista o de rechazar el uso del Sitio de Appeptito y los sitios web relacionados, servicios o aplicaciones de Appeptito si el Comisionista no cumple con los requisitos anteriores.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>3.2 El Comisionista reconoce y acepta que los USUARIOS/CONSUMIDORES pueden optar por cancelar los pedidos de Servicios de Entrega que han sido aceptados por el Comisionista a través de Appeptito en cualquier momento antes de su llegada a los lugares de recolección o Negocios. En este caso, Appeptito puede cobrarle al Usuario un cargo por cancelación en nombre del Socio de Repartidor. Si se cobra, esta tarifa de cancelación se considerará la Tarifa de Entrega para los Servicios de Entrega cancelados con el propósito de ser remitida al Comisionista.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>3.3 El Comisionista reconoce y acepta que la prestación de Servicios de Entrega a los Usuarios crea una relación comercial directa entre el Comisionista y el USUARIO/CONSUMIDOR. El Comisionista será el único responsable de cualquier obligación o responsabilidad con los USUARIOS/CONSUMIDORES, los destinatarios de la entrega u otros terceros que surjan de la prestación de los Servicios de Entrega.</span>
		</p>
		<p style={{marginTop:'14px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>OBLIGACIONES Y GARANTÍAS</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>4.1 Los Comisionistas cumplirán con las siguientes normas para la contratación de Servicios de transporte entrega:</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>i. cumplirán con cualquier requerimiento normativo obligatorio y vigente, para ser un Comisionista calificado, establecido por cualquier ley o reglamento aplicable;</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>ii. utilizarán, el Kit de Entrega con la marca Appeptito, de igual forma, bajo su más estricta responsabilidad utilizara las medidas de protección previstas en las normas de Tránsito y Vialidad vigentes y positivas, en el lugar en que ha de prestar el Servicio al USUARIO/CONSUMIDOR;</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>iii. contarán con una licencia de conducir vigente, cuando el transporte y entrega se realice utilizando un automóvil o una motocicleta;</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>iv. contarán con una póliza de seguro de responsabilidad civil por daños y perjuicios contra terceros, cuando el transporte y entrega se realice utilizando un automóvil o una motocicleta;</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>v. llevarán casco y protecciones en caso de motocicletas y bicicletas;</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>vi. se sujetará y cumplirá con todas y cada una de las normas de Tránsito y Vialidad vigentes y positivas, en el lugar en que ha de prestar el Servicio al USUARIO/CONSUMIDOR,</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>vii. hablarán español como idioma nativo;</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>viii. contar con un certificado de no antecedentes penales (en un periodo máximo de 2 meses);</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>ix. no tendrán antecedentes por conducción peligrosa;</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>x. no tendrán antecedentes de conducir en estado de ebriedad;</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>xi. no tendrán antecedentes de adicción a las drogas.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Las siguientes enfermedades o actos podrían poner en riesgo la seguridad del Comisionista:</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>i. Enfermedad cardiaca orgánica;</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>ii. Epilepsia;</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>iii. Síndrome de Meniere;</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>iv. Vértigo;</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>v. Histeria;</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>vi. Enfermedad de Parkinson;</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>vii. Psicopatías;</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>viii. Demencia y enfermedades del sistema nervioso que afecten el uso de las extremidades;</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>ix. Tomar o inyectarse drogas, tomar fármacos psicotrópicos que crean dependencia por un tiempo prolongado, volverse adicto y no dejarlos;</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>x. Cualquiera otra condición prevista en las leyes y reglamentos nacionales o medidas de implementación locales.</span>
		</p>
		<p style={{marginTop:'14px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>EL VEHÍCULO REGISTRADO CUMPLIRÁ CON LOS SIGUIENTES ESTÁNDARES PARA VEHÍCULOS DE REPARTO CONSISTENTES EN AUTOMÓVILES Y MOTOCICLETAS.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>i. Los vehículos podrán contener la Caja de Entrega de Appeptito y/o mochila, la cual deberá estar seca, limpia, ser resistente a la corrosión, impermeable, no tóxica, estar en buenas Condiciones y se puede limpiar fácilmente;</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>ii. El Comisionista deberá tener posesión o propiedad legal del vehículo registrado;</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>iii. Tener una licencia de conducir válida, registro, certificado de verificación de tarjeta de circulación y seguro de vehículo;</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>iv. La carrocería del vehículo deberá estar limpia y libre de polvo, sin daños ni rasguños evidentes;</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>v. La matrícula del vehículo deberá ser clara;</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>vi. No tener pegatinas publicitarias, accesorios, colgantes u otros adornos;</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>vii. El vidrio del vehículo será deberá tener una película de protección, en caso de ser automóvil;</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>viii. Los certificados y partes del vehículo estén completas;</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>ix. Cualquier otro asunto prescrito por las leyes y regulaciones aplicables.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Para Entregas en Bicicleta.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>i. Contener caja de entrega y/o mochila, que debe estar seca, limpia, suave, resistente a la corrosión, impermeable, no tóxica, en buenas condiciones y se puede limpiar fácilmente;</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>ii. El cuerpo de la bicicleta deberá estar limpio y libre de polvo, sin daños ni rasguños evidentes;</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>ii. El cuerpo de la bicicleta deberá estar limpio y libre de polvo, sin daños ni rasguños evidentes;</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>iii. No deberá tener pegatinas publicitarias, accesorios, colgantes u otros adornos;</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>iv. Cualquier otro asunto prescrito por las leyes y regulaciones aplicables.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>4.3 Es responsabilidad del Comisionista mantener actualizado todo el hardware o software del dispositivo móvil para que se ajuste a los requisitos actuales de Appeptito. La Empresa no es responsable de ningún problema que pueda surgir cuando el Comisionista utilice una versión no actualizada o la más reciente de Appeptito y/o use Appeptito en cualquier dispositivo móvil que no cumpla con los requisitos actuales vigentes de Appeptito. El Comisionista acuerda que la Empresa puede actualizar los requisitos del hardware y software del teléfono móvil de Appeptito periódicamente.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>4.4 Al utilizar los Servicios, el Comisionista también acuerda lo siguiente:</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>i. El Comisionista utilizará los Servicios o Appeptito de buena fe;</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>ii. El Comisionista utilizará los Servicios o Appeptito solo para uso personal y no revenderá a terceros;</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>iii. El Comisionista no utilizará los Servicios o Appeptito para ningún propósito ilegal;</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>iv. El Comisionista no utilizará los Servicios o Appeptito para acosar u obstruir a otros o causar inconvenientes a otros;</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>v. El Comisionista no afectará el funcionamiento normal de la red;</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>vi. El Comisionista no intentará dañar los Servicios o Appeptito; y</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>vii. El Comisionista cumplirá con todas las leyes aplicables del país/región, provincia y/o ciudad en la que el Comisionista utilice la aplicación o los Servicios.</span>
		</p>
		<p style={{marginTop:'14px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>SERVICIO Y CUOTA DE COOPERACIÓN</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>5.1 Para cada pedido entregado, Appeptito tiene un mecanismo para el cálculo de la contraprestación, el cual calcula la tarifa con un algoritmo que toma en consideración una tarifa base, horario de pedido, zonas sociodemográficas y la distancia desde las ubicaciones de recolección y destino de los Productos correspondientes que deben ser entregados.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>5.2 El Comisionista acuerda que la Empresa puede hacer un acuerdo con él de forma regular o irregular por la cooperación mencionada en función del estándar de liquidación actualizado periódicamente. El Comisionista reconoce y acepta que Appeptito podrá contratar a un tercero que, en nombre de la Empresa y el Comisionista, recaudará los montos relacionados con los Servicios de entrega de los USUARIOS/CONSUMIDORES.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>5.3 El Comisionista reconoce y acepta que será responsable del pago de los impuestos y demás contribuciones aplicables con los Servicios de entrega y a los ingresos derivados de los mismos, o de cualquier otra actividad, incluyendo, pero sin limitarse a, el Impuesto sobre el Valor Agregado y el Impuesto Sobre la Renta, así como a la presentación de las declaraciones correspondientes. Asimismo, para tales efectos, el Comisionista otorga su autorización para que, de acuerdo con las disposiciones jurídicas aplicables y cualquier acuerdo con las autoridades fiscales competentes, Appeptito lleve a cabo la retención y entrega de información de los servicios prestados por parte del Comisionista a la autoridad fiscal, en la forma y términos definidos por la misma.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>5.4 El Comisionista reconoce y acepta que Appeptito podrá facilitar la emisión de los comprobantes fiscales que el Comisionista esté obligado a emitir de conformidad con las leyes y reglamentos aplicables, el Comisionista reconoce que dicha facilidad podrá consistir en la emisión de dichos comprobantes fiscales por parte de Appeptito, o cualquier tercero designado por Appeptito para tales efectos, a nombre y por cuenta del Comisionista, en los términos de la regla 3.11.12. de la Resolución Miscelánea Fiscal para 2019 (o cualquier disposición que la sustituya), o mediante cualquier otro mecanismo que Appeptito considere apropiado. El Comisionista reconoce que ni Appeptito ni cualquier tercero designado por Appeptito para dicho propósito serán responsables por la emisión de los comprobantes fiscales derivados de la aplicación de dicha regla 3.11.12., requeridos por los Usuarios por los servicios de entrega a través de plataforma. Ni Appeptito ni cualquier tercero designado por Appeptito para tales efectos no serán responsables por cualquier error o falla en la emisión de los comprobantes fiscales aplicables.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>5.5 El Comisionista reconoce que todas las facturas que el Usuario pueda requerir por el servicio de entrega, serán emitidas por el Comisionista según lo exijan las leyes o regulaciones aplicables para cumplir con los requisitos fiscales mexicanos. Aunque Appeptito puede facilitar a los Socios Repartidores a emitir facturas, Appeptito no será responsable de la emisión de ninguna factura requerida por el Usuario para el servicio de entrega prestado, y no será responsable de ningún error o falta de entrega de la factura fiscal correspondiente.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>5.6 Los servicios de telecomunicaciones y las tarifas relacionadas, en las que se puede incurrir al utilizar Appeptito, son proporcionados y cobrados por los operadores de telecomunicaciones y pagos en línea, no por Appeptito y correrán a cargo del propio Comisionista.</span>
		</p>
		<p style={{marginTop:'14px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>GARANTÍA DE SERVICIO</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>6.1 Cuando el Comisionista acepte la solicitud de entrega en línea o pedidos del USUARIO/CONSUMIDOR, el Comisionista será responsable de la calidad del servicio, la entrega correcta de los Productos y la conducción segura de acuerdo con las disposiciones de estos Términos y Condiciones y las disposiciones jurídicas aplicables en su jurisdicción.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>6.2 El Comisionista reconoce y acepta que él es el único responsable de todas las pérdidas, contaminación o sustancias tóxicas que puedan tener los Productos debido a su forma ineficiente para llevar a cabo cada entrega o por tener medidas sanitarias deficientes en la Caja de Entrega.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>6.3 El Comisionista será responsable de las horas extras de las entregas, o si el pedido se entrega a una dirección incorrecta o un destinatario incorrecto.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>6.4 En el caso de cualquier daño o perjuicio causado por culpa del Comisionista que un tercero sufra, el Comisionista cubrirá la indemnización que corresponda. En caso de que el Comisionista sea culpable de cualquier daño o perjuicio o que su incumplimiento de los presentes Términos y Condiciones cause cualquier pérdida a la Empresa, la Empresa tendrá derecho a reclamar una indemnización al Comisionista.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>6.5 El Comisionista reconoce que no existe una relación laboral o subordinada entre el Comisionista y Appeptito o cualquiera de sus afiliadas, derivada de todas y cada una de las actividades realizadas por el Comisionista a las cuales les son aplicables estos Términos y Condiciones, de conformidad con las leyes y reglamentos laborales, de seguridad social y fiscales aplicables en México.</span>
		</p>
		<p style={{marginTop:'14px', marginBottom:'14px', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>RESPONSABILIDAD DEL COMISIONISTA POR INCUMPLIMIENTO</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>7.1 El Comisionista proporcionará los Servicios de Entrega de acuerdo con las normas de servicio acordadas y las leyes aplicables en la jurisdicción del Comisionista. El Comisionista realizará los Servicios de Entrega de buena fe, y no deberá:</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>i. divulgar cualquier información inexacta o incompleta;</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>ii. divulgar la información del Usuario;</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>iii. cancelar el servicio de entrega una vez aceptado;</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>iv. sustituir al repartidor o el vehículo sin permiso;</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>v. dejar los Productos en una ubicación incorrecta;</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>vi. desviarse deliberadamente;</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>vii. cobrar tarifas ilegalmente;</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>viii. realizar Servicios de Entrega off-line;</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>ix. solicitar costos o propinas irrazonables a los USUARIOS/CONSUMIDORES;</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>x. causar algún tipo de daño del Usuario que se quejó de su servicio o de la calidad de los Productos o hizo comentarios insatisfactorios sobre su servicio;</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>xi. insultar a los Usuarios o empleados del restaurante;</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>xii. cometer conductas deshonestas o fraudulentas con el fin de obtener un mayor rendimiento o cualquier ventaja financiera a través del uso de la plataforma Appeptito;</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>xiii. estar o participar en cualquier altercado físico con los Usuarios, o cometer actos ilegales, como peleas, riñas, robo y obscenidad. Cualquier conducta descrita en los incisos i) a xii) se denomina en adelante el "Acto ilícito”.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>7.2 Si el Comisionista realiza Actos Ilícitos, se considerará que el Comisionista está incumpliendo estos Términos y Condiciones sin importar que el USUARIO/CONSUMIDOR, los terceros y/o los Negocios presenten quejas contra él, y la Empresa tiene el derecho de terminar la cooperación con el Comisionista según sea el caso. La Empresa tiene el derecho de exigir que el Comisionista realice una indemnización total por la pérdida causada por el Acto Ilícito.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>7.3 Después de aceptar la solicitud de pedido (“Orden de Aceptación) el Comisionista no cancelará el pedido, ni rechazará la prestación de Servicios de Entrega al Usuario, ni inducirá u obligará al Usuario a cancelar el pedido (en lo sucesivo, "Cancelación después de la Aceptación del Pedido"), a menos que el vehículo esté inhabilitado para proporcionar servicios, o cualquier otra razón legítima basada en las leyes o regulaciones existentes de la jurisdicción del Comisionista. El Comisionista acepta y autoriza a la Empresa a supervisar los datos relativos a la cancelación después de la aceptación del pedido. La cancelación indebida después de la aceptación del pedido se juzgará a discreción exclusiva de Appeptito y se considerará como un incumplimiento de estos Términos y Condiciones.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>7.4 Si el Comisionista incumple cualquier punto que haya acordado con Appeptito o sus filiales, la Empresa podrá, a su exclusivo criterio, cancelar la cuanta del Comisionista.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>7.5 El Comisionista acuerda indemnizar a la Empresa y a los funcionarios, directores y empleados de la Empresa, inmediatamente de cualquier demanda, reclamaciones, responsabilidad, daños, costos y gastos, incluidos los honorarios legales, que surjan de cualquier incumplimiento de estos Términos y Condiciones respecto del Comisionista y Appeptito o cualquier otra responsabilidad que surja del uso de Appeptito por parte del Comisionista.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>El Comisionista reconoce y acepta que, al concluir cualquier Servicio de Entrega, Appeptito puede solicitar al USUARIO/CONSUMIDOR del Servicio que valore dicho Servicio de Entrega y, opcionalmente, proporcione comentarios u opiniones sobre el Comisionista en relación con dicho Servicio de Entrega.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Para continuar teniendo acceso a Appeptito como Comisionista, el Comisionista debe mantener una calificación promedio que exceda el valor promedio mínimo aceptable que Appeptito establece para ese país o territorio, promedio que Appeptito puede actualizar a su exclusivo criterio (la "Calificación Promedio Mínima Calificación Promedio Mínima"). La calificación promedio pretende reflejar la satisfacción del USUARIO/CONSUMIDOR con los Servicios de Entrega que proporciona el Comisionista, así como el grado de cumplimiento que el Comisionista alcanza con respecto a las políticas o recomendaciones de Appeptito. Si la valoración promedio del Comisionista cae por debajo de la Calificación Promedio Mínima, Appeptito notificará este hecho al Comisionista y podrá asignar, a su entera discreción, un periodo limitado para aumentar la calificación promedio del Comisionista por encima de la Calificación Promedio Mínima. Si el Comisionista no aumenta su calificación promedio por encima de la Calificación Promedio Mínima dentro del período establecido (si corresponde), Appeptito se reserva el derecho de desactivar el acceso del Comisionista al Servicio.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Además, el Comisionista reconoce que su continuo rechazo de solicitudes de Servicios de Entrega realizadas por USUARIOS/CONSUMIDORES mientras está en línea genera una experiencia negativa para los Usuarios de Appeptito. Si el Comisionista no acepta ningún servicio de entrega mientras está en línea, se desconectará de la sesión.</span>
		</p>
		<p style={{marginTop:'14px', marginBottom:'14px', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>RESPONSABILIDAD DEL COMISIONISTA POR ENGAÑO O FRAUDE</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Cualquier conducta para obtener concesiones, recompensas u otros beneficios a través de cualquier forma de comportamiento engañoso o reventa son contrarias a los presentes Términos y Condiciones y a las disposiciones jurídicas aplicables, por lo que la Empresa se opondrá a tales conductas y tomará las acciones disuasivas, preventivas y/o correctivas necesarias. El Comisionista asumirá las responsabilidades de dichas conductas engañosas o de fraude, y dichas conductas y la Empresa se reserva el derecho de iniciar las acciones legales que procedan contra las mismas.</span>
		</p>
		<p style={{marginTop:'14px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>SUSPENSIÓN Y TERMINACIÓN</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>9.1 La Empresa determinará, a su entera discreción, si el Comisionista ha ocurrido un incumplimiento de los presentes Términos y Condiciones, en cuyo caso, la Empresa podrá tomar las medidas que considere adecuadas.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>9.2 Un incumplimiento de los presentes Términos y Condiciones podrá resultar en las siguientes medidas:</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>i. Notificación del incumplimiento incurrido y reiteración de las obligaciones correspondientes y de las consecuencias en caso de no darles cumplimiento;</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>ii. Notificación a las autoridades competentes sobre actos que impliquen incumplimiento de los Términos y Condiciones o de las disposiciones jurídicas aplicables, así como de cualquier dato relevante;</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>iii. Suspensión o retiro inmediato, temporal o permanente del derecho del Comisionista de utilizar Appeptito; y</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>iv. Acciones legales en contra del Comisionista para reclamar los daños y perjuicios causados a Appeptito o sus filiales, por causas atribuibles al Conductor (incluyendo, sin limitación, costos razonables administrativos y de abogados) así como cualesquiera otras acciones legales adicionales en contra del Comisionista.</span>
		</p>
		<p style={{marginTop:'14px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>RESTRICCIONES REGULATORIAS LOCALES</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Appeptito no está dirigida a persona alguna en cualquier jurisdicción en la que (debido a la nacionalidad, residencia u otro motivo) el acceso a o la disponibilidad de Appeptito esté prohibido o este sujeto a cualquier restricción, incluyendo requerimientos de registro u otros requerimientos dentro de dicha jurisdicción. Appeptito se reserva el derecho de limitar el acceso a Appeptito a cualquier persona. Las personas que accedan a Appeptito lo hacen por iniciativa propia y serán responsables de cumplir con las disposiciones jurídicas aplicables. Appeptito no será responsable frente a Usuario alguno por cualquier pérdida o daño, ya sea conforme una estipulación contractual, por responsabilidad civil (incluyendo negligencia), incumplimiento de un deber conforme a derecho o por otro motivo, aun cuando sea previsible, derivado de o relacionado con el uso por una persona en una jurisdicción donde el acceso a o la disponibilidad de Appeptito esté prohibido o sujeto a cualquier restricción. En caso de duda, el Comisionista deberá obtener asesoría legal independiente.</span>
		</p>
		<p style={{marginTop:'14px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>AUSENCIA DE GARANTÍAS</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>11.1 Appeptito y sus afiliados proporcionan, y el Comisionista acepta, los Servicios en Appeptito y los sitios web relevantes de Appeptito "tal como están" y "según estén disponibles". Appeptito y sus afiliados no representan, aseguran ni garantizan que su acceso o uso de los Servicios de Appeptito o Appeptito se realice de forma ininterrumpida o sin errores, o dará lugar a solicitudes de Servicios de Entrega. Appeptito y sus afiliados funcionan como una aplicación móvil que permite a los Usuarios organizar y programar servicios de logística y entrega, y no hacen representaciones, garantías o garantías en cuanto a las acciones o inacciones de los Usuarios o destinatarios de la entrega que pueden solicitar o recibir Servicios de Entrega del Comisionista. Appeptito ni sus afiliados evalúan a los Usuarios ni a los destinatarios de la entrega. Al utilizar los servicios Appeptito y Appeptito, el Comisionista reconoce y acepta que puede ser presentado a un tercero que puede suponer un daño o riesgo para el Comisionista o para terceros. Se recomienda al Comisionista que tome precauciones razonables con respecto a las interacciones con terceras partes en relación con el uso de los servicios Appeptito o Appeptito. Appeptito no representa ni garantiza la seguridad de ningún artículo proporcionado a los Usuarios para los Servicios de Entrega. Appeptito y sus afiliadas renuncian expresamente a cualquier responsabilidad por cualquier acto u omisión del Comisionista, cualquier Usuario, restaurante u otro tercero.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>11.2 Excepto por lo expresamente establecido en estos Términos y Condiciones, todas las garantías, Condiciones y representaciones expresadas o implícitas o indicadas de otro modo por Appeptito (incluyendo, sin limitación, garantías de calidad satisfactoria, aptitud para el propósito o habilidad y cuidado) quedan excluidas por esta estipulación cando la legislación aplicable así lo permita.</span>
		</p>
		<p style={{marginTop:'14px', marginBottom:'14px', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>12. LIMITACIÓN DE LA RESPONSABILIDAD DE Appeptito</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>12.1 La información y los Servicios recomendados al Comisionista en los sitios web de Appeptito son solo para referencia del Comisionista. Appeptito realizará un esfuerzo razonable para garantizar la exactitud de dicha información, siempre que Appeptito no garantice que dicha información esté libre de errores, defectos, software malintencionados o virus.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>12.2 Appeptito no será responsable por ningún daño que resulte del uso de, o cualquier incapacidad de uso de los sitios web de Appeptito o Apps móviles de Appeptito. Además, Appeptito no será responsable por ningún daño que resulte del uso de, o cualquier incapacidad para utilizar las herramientas de comunicación electrónica de los sitios web de Appeptito o Apps móviles de Appeptito, incluidos, sin limitación, cualquier daño causado por un fallo en la transmisión, mal funcionamiento de Internet o retraso de la comunicación electrónica, interceptación o manipulación de la comunicación electrónica por un tercero programa de computadora utilizado para la comunicación electrónica y la transmisión de virus, falla de energía, huelgas o cualquier otra disputa laboral, disturbios, insurrección, agitación, terremoto, incendio, inundación, tormenta, explosión, guerra; acto gubernamental, orden de las autoridades judiciales o administrativas, o cualquier otro evento de caso fortuito o causa de fuerza mayor u omisión de terceros.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>12.3 Appeptito no será responsable de ningún daño indirecto, incidental, especial, ejemplar, punitivo o consecuente, incluida la pérdida de beneficios, la pérdida de datos, lesiones personales o daños a la propiedad relacionados con cualquier uso de los Servicios, o de cualquier otro modo, independientemente de la negligencia (ya sea activa, afirmativa, única o concurrente) de Appeptito, incluso si Appeptito ha sido informado de la posibilidad de tales daños.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>12.4 Appeptito no garantiza la exactitud, integridad, adecuación y confiabilidad de la información y el contenido de los sitios web de Appeptito o Apps móviles de Appeptito, incluidos, entre otros, textos, imágenes, datos, opiniones, páginas web o enlaces, a pesar de sus esfuerzos por proporcionar información precisa y precisa. Información exhaustiva en la medida de lo posible. Appeptito no se responsabiliza de ningún error u omisión, ni otorga ninguna garantía explícita o implícita.</span>
		</p>
		<p style={{marginTop:'14px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>CONFIDENCIALIDAD</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>El Comisionista reconoce y acepta que, en el desempeño de los Servicios de Entrega, puede tener acceso o estar expuesto directa o indirectamente a información confidencial ("Información confidencial"). La información confidencial incluye los datos de Appeptito y sus afiliados, las identificaciones de los proveedores, la información del Usuario, la información del destinatario de la entrega, la información de los Negocios, la información del paquete, los planes de marketing y de negocios de Appeptito, la información financiera, técnica, operacional y cualquier otra información no pública (ya sea revelada por escrito o verbalmente), por lo tanto, el Comisionista reconoce y acepta no divulgar Información Confidencial a ningún tercero y deberá devolver o destruir toda la Información Confidencial, una vez que estos Términos y Condiciones o a solicitud de Appeptito sean cancelados.</span>
		</p>
		<p style={{marginTop:'14px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>VIGENCIA</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Cada una de las partes puede rescindir este acuerdo: (a) sin la necesidad de presentar ningún motivo con un aviso de siete (7) días por escrito a la otra parte; (b) inmediatamente, sin previo aviso, por incumplimiento sustancial de estos Términos y Condiciones; (c) inmediatamente, sin previo aviso, en caso de quiebra o declaración de insolvencia de la otra parte.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Además, la Empresa puede rescindir este acuerdo si el Comisionista:</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>i. Ya no puede proporcionar los Servicios de conformidad con las leyes, normas y/o políticas aplicables de Appeptito o la Empresa para proporcionar Servicios de Entrega o para operar medios de transporte;</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>ii. Está acusado de un crimen, delito o delito menor;</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>iii. Contraviene o incumple los Términos y Condiciones, políticas o pautas de la Empresa;</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>iv. el Comisionista infringe o incumple cualquiera de estos Términos y Condiciones;</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>v. A criterio exclusivo de Appeptito, si el Comisionista abusa de Appeptito, los Servicios o los sitios web de Appeptito;</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>vi. Incumple lo dispuesto en este documento.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>17.3 Incluso si Appeptito da por terminados estos Términos y Condiciones, el Comisionista continuará siendo responsable de cualquier daño o pérdida que pueda derivarse de dicho incumplimiento.</span>
		</p>
		<p style={{marginTop:'14px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Fuerza Mayor</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>18.1 En caso de que se presente una fuerza mayor, la parte afectada podrá suspender temporalmente el cumplimiento de las obligaciones a su cargo hasta que el efecto de dicha causa de fuerza mayor cese, y no incurrirá en incumplimiento; en el entendido, no obstante que dicha parte hará su mejor esfuerzo para resolver dicha causa y mitigar las pérdidas. Fuerza mayor significa cualquier causa impredecible e inevitable (aun cuando sea previsible) fuera del control de las partes que impida, afecte o demore el cumplimiento por una parte de todas o cualquiera de sus obligaciones conforme al presente instrumento. Dichas causas incluyen, sin limitación, terremotos, guerra, modificación de las leyes reglamentos y políticas gubernamentales, virus de cómputo, ataques de hackers o suspensión de servicios prestados por empresas de telecomunicación.</span>
		</p>
		<p style={{marginTop:'14px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>REGLAMENTO PARA REPARTIDORES</span>
		</p>
		<p style={{marginTop:'14px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<u><span style={{fontFamily:'Arial'}}>Vestimenta</span></u>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>- El repartidor deberá vestirse apropiadamente para dar una buena imagen, preferentemente de color negro.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>- La vestimenta deberá estar siempre limpia.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>- Queda prohibido el uso de short´s y camisetas.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>- Queda prohibido el uso de accesorios (gafas, aretes, collares, pulseras, piercing).</span>
		</p>
		<p style={{marginTop:'14px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<u><span style={{fontFamily:'Arial'}}>Motocicleta</span></u>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>- Queda estrictamente prohibido utilizar una motocicleta diferente a la registrada en la plataforma.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>- La motocicleta deberá encontrarse limpia en todo momento.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>- Revisar antes de iniciar labores el estado de la motocicleta, esta deberá estar en óptimas condiciones.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>- Contar con el combustible necesario para evitar retrasos de entrega.</span>
		</p>
		<p style={{marginTop:'14px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<u><span style={{fontFamily:'Arial'}}>Manejo</span></u>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>- Manejar de forma ordenada para evitar accidentes.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>- Es obligatorio el uso de casco completo.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>- Respetar señalamientos, límites de velocidad, sentido vehicular y semáforos.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>- Respetar el 1x1.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>- No estacionarse en lugares prohibidos.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>- Evitar maniobras bruscas que puedan ocasionar que los productos se caigan o volteen.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>- Queda estrictamente prohibido llevar acompañantes durante la jornada laboral que sean menores de edad.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>- Portar en todo momento la licencia de conducir e INE</span>
		</p>
		<p style={{marginTop:'14px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<u><span style={{fontFamily:'Arial'}}>Lenguaje</span></u>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>- Queda prohibido el uso de palabras obscenas.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>- Dirigirse siempre con respeto a los usuarios.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>- Saludar de forma educada cuando arribe al negocio o domicilio.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>- Despedirse de forma educada cuando parta del negocio o domicilio.</span>
		</p>
		<p style={{marginTop:'14px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<u><span style={{fontFamily:'Arial'}}>Recolección y entrega de productos</span></u>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>- Llegar por lo menos 5 minutos antes de que se cumpla el horario de recolección.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>- Validar que el producto recibido sea el que se menciona en la aplicación.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>- Queda estrictamente prohibido abrir los productos.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>- Acomodar correctamente los productos para que no se muevan durante el traslado.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>- Mantener la mochila cerrada durante el traslado de los productos para evitar que se caigan.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>- En caso de que algún producto se derrame deberá limpiarse antes de entregarse al USUARIO/CONSUMIDOR.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>- Realizar la entrega en el tiempo indicado.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<u><span style={{fontFamily:'Arial'}}>Uso del teléfono y aplicación</span></u>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>- Mantener con suficiente batería el teléfono durante la jornada laboral.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>- Contar con el saldo suficiente para mantenerse conectado a la red de internet.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>- Estar al pendiente de las notificaciones enviadas a través de la aplicación.</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>- Dar seguimiento a los estatus del pedido.</span>
		</p>
		<p style={{marginTop:'14px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<u><span style={{fontFamily:'Arial'}}>VIGENCIA Y TERMINACIÓN</span></u>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Los presentes Términos y Condiciones acordados por Usted y Appeptito, así como sus actualizaciones, comenzarán a surtir efectos desde la fecha en que Usted acepte su contenido de manera electrónica, y estarán en vigor durante todo el tiempo en que el Usted utilice la aplicación de Appeptito para hacer uso de los Servicios.</span>
		</p>
		<p style={{marginTop:'14px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>PROPIEDAD INTELECTUAL</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Appeptito es titular de los derechos de propiedad intelectual de todo el contenido distribuido en la Aplicación Móvil o los sitios web de Appeptito, incluyendo, sin limitación, el software proporcionado y los productos o servicios relacionados, y tales derechos de propiedad intelectual están protegidos por las leyes.</span>
		</p>
		<h1 style={{marginTop:'0px', marginBottom:'0px', pageBreakInside:'avoid', pageBreakAfter:'avoid', lineHeight:'108%', fontSize:'12px'}}>
			<span style={{fontFamily:'Arial', fontWeight:'normal'}}>La Aplicación Móvil o los sitios web de Appeptito pertenecerán en todo momento como propiedad exclusiva de Cafi DC S.A.</span>
		</h1>
		<p style={{marginTop:'14px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>&#xa0;</span>
		</p>
		<p style={{marginTop:'14px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>LEGISLACIÓN APLICABLE Y JURISDICCIÓN</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Para la interpretación y ejecución de los presentes términos de uso consumidor, el Consumidor acepta someterse a la jurisdicción y competencia de los tribunales competentes de la Ciudad de México renunciando expresamente al fuero que pudiese corresponderle por razón de su domicilio presente o futuro, reconociendo que su solución, en su caso, debe hacerse por la vía mercantil, aplicándose el Código de Comercio y supletoriamente el Código Civil Federal.</span>
		</p>
		<p style={{marginTop:'14px', marginBottom:'14px', textAlign:'justify', lineHeight:'normal', fontSize:'12px', backgroundColor:'#ffffff'}}>
			<span style={{fontFamily:'Arial'}}>Appeptito 2023 Derechos Reservados</span>
		</p>
		<p style={{marginTop:'0px', marginBottom:'8px', textAlign:'justify'}}>
			&#xa0;
		</p>
		<div style={{height: '30px'}}></div>
		</div>
      </>
    ) ;
  }
}

export default withRouter(Detail);
