import { initializeApp } from 'firebase/app';
import { Timestamp, doc, getFirestore, updateDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from 'firebase/storage';
// import { getMessaging, getToken, onMessage } from '@firebase/messaging';

const firebaseConfig = {
  apiKey: (process.env.NODE_ENV === "production")?"AIzaSyC1XV9QB4ycpK9pIZb1hdj6A8LKbDxJefk":"AIzaSyCKS4UV1My-BfBeRa114cI45VVBkUbZ_Eg",
  authDomain: "senor-delivery-app.firebaseapp.com",
  projectId: "senor-delivery-app",
  storageBucket: "senor-delivery-app.appspot.com",
  messagingSenderId: "927934116144",
  appId: "1:927934116144:web:429be93a305bcf352d18f5",
  measurementId: "G-9LHNYFSKKZ"
};

  export const app = initializeApp(firebaseConfig);
  export const db = getFirestore(app);
//   export const messaging = getMessaging(app);
  export const auth = getAuth(app);
  export const storage = getStorage(app);

//   export const requestForToken = (userId) => {
//     try {
//       Notification.requestPermission().then((permission) => {
//         if (permission === 'granted') {
//           console.log('Notification permission granted.');
//           try {
//             getToken(messaging, { vapidKey: "BGfWM5S8VqokWSafe5Hn1YTZ0dOsJUHAR8b0NobKcU9Ly1C3fXTmhKbUcm_0CD-IcPq2O2nfW-_aFepWoY_vliA" })
//             .then(async (currentToken) => {
//               if (currentToken) {
//                 console.log('current token for client: ', currentToken);
//                 try {
//                 // Perform any other neccessary action with the token
//                 await updateDoc(doc(db, 'users', userId), {
//                   'token': currentToken,
//                   'lastLogin': Timestamp.now(),
//                   'isSignedIn': true,
//                 });
//               } catch(e) {
//                 console.log(e);
//               }
//               } else {
//                 // Show permission request UI
//                 console.log('No registration token available. Request permission to generate one.');
//               }
//             })
//             .catch((err) => {
//               console.log('An error occurred while retrieving token. ', err);
//               setTimeout(
//                 () => {
//                   try {
//                     getToken(messaging, { vapidKey: "BGfWM5S8VqokWSafe5Hn1YTZ0dOsJUHAR8b0NobKcU9Ly1C3fXTmhKbUcm_0CD-IcPq2O2nfW-_aFepWoY_vliA" })
//                     .then(async (currentToken) => {
//                       if (currentToken) {
//                         console.log('current token for client: ', currentToken);
//                         try {
//                         // Perform any other neccessary action with the token
//                         await updateDoc(doc(db, 'users', userId), {
//                           'token': currentToken,
//                           'lastLogin': Timestamp.now(),
//                           'isSignedIn': true,
//                         });
//                       } catch(e) {
//                         console.log(e);
//                       }
//                       } else {
//                         // Show permission request UI
//                         console.log('No registration token available. Request permission to generate one.');
//                       }
//                     })
//                     .catch((err) => {
//                       console.log('An error occurred while retrieving token. ', err);
//                     });
//                   } catch(e) {
//                     console.log(e);
//                   }
//                 } , 5000
//               );
  
//             });
//           } catch(e) {
//             console.log(e);
//           }
//         } else {
//           console.log('Unable to get permission to notify.');
//         }
//       }).catch((err) => {
//         console.log('An error occurred while retrieving permission. ', err);
//       });
  
//     } catch(e) {
//       console.log(e);
//     }
//   };
  
  
//   export const onMessageListener = () => {
//     console.log("onMessageListener");
//   return new Promise((resolve, reject) => {
//     try {
//     onMessage(messaging, (payload) => {
//       console.log("payload", payload)
//       resolve(payload);
//     });
//   } catch(e) {
//     reject(e);
//   }
//   });
//   }
